import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Col, Label, Row } from "react-bootstrap";
import Layout from "../Layout/Layout.js";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import SplitButton from "react-bootstrap/SplitButton";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { Pagination } from "antd";
import { Drawer, Flex, Button } from "antd";
import { Radio, Space } from "antd";
import { DatePicker } from "antd";
import baseURL from "../Config/configFile.js";
import { Select } from "antd";
import { Tag } from "antd";
import { Modal as AntModalCustomFilter } from "antd";
import { Input } from "antd";
import { Typography } from "antd";
import EditFilled from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
const dateFormat = "YYYY-MM-DD";
const dateFormatOne = "YYYY-MM-DD";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const color = ["#f50", "#2db7f5", "#87d068", "#108ee9"];

function OrderPage() {
  /* const search = useLocation().search;
  const shop = new URLSearchParams(search).get('shop');
  const code = new URLSearchParams(search).get('code'); */
  //const { shop,code } = useParams();
  const navigate = useNavigate();
  const GotoOrderDetailsPage = (orderID) => {
    navigate("/orderDetails", {
      replace: false,
      state: { id: orderID, staffArray: staffArray },
    });
  };
  const [customFilterName, setCustomFilterName] = useState("");
  const [
    customFilterFulfilmentStatusArray,
    setcustomFilterFulfilmentStatusArray,
  ] = useState([]);
  const [
    customFilterOrderStatusArray,
    setcustomFilterOrderStatusArray,
  ] = useState([]);
  const [
    customFilterOrderDeliveryMethod,
    setcustomFilterOrderDeliveryMethod,
  ] = useState([]);
  const [customFilterDeliveryStatusArray, setcustomFilterDeliveryStatusArray] =
    useState([]);
  const [
    customFilterFinancialStatusArray,
    setcustomFilterFinancialStatusArray,
  ] = useState([]);
  const [
    refreshModal,
    setrefreshModal,
  ] = useState(true);
  const [customFilterCreated, setcustomFilterCreated] = useState(false);

  const [openNewModalCustomFilter, setOpenNewModalCustomFilter] =
    useState(false);
  const [editAntModalCustomFilter, setEditAntModalCustomFilter] =
    useState(false);
  const [openAntModalCustomFilter, setOpenAntModalCustomFilter] =
    useState(false);
    const [openAntModalPickListPopup, setOpenAntModalPickListPopup] =
    useState(false);
    const [View,setView]=useState(localStorage.getItem("view")==null?"[]":localStorage.getItem("view"))

  const handleChangeonCustomFilterFulfillmentStatus = (value) => {
    setcustomFilterFulfilmentStatusArray(value);
  };
  const handleChangeonCustomFilterOrderStatusArray = (value) => {
    setcustomFilterOrderStatusArray(value);
  };
  const handleChangeonCustomFilterOrderDeliveryMethod = (value) => {
    setcustomFilterOrderDeliveryMethod(value);
  };
  const handleChangeonCustomFilterDeliveryStatus = (value) => {
    setcustomFilterDeliveryStatusArray(value);
  };
  const handleChangeonCustomFilterFinancialStatus = (value) => {
    setcustomFilterFinancialStatusArray(value);
  };
  const createCustomFilter = async () => {
    var customFilterData = {
      shop: localStorage.getItem("shop"),
      staffName: localStorage.getItem("staffName"),
      staffId: localStorage.getItem("staffId"),
      customFilterName: customFilterName,
      fulfilmentStatus: customFilterFulfilmentStatusArray,
      financialStatus: customFilterFinancialStatusArray,
      deliveryStatus: customFilterDeliveryStatusArray,
      taggedWith: filterTags,
      notTaggedWith: filterNotTags,
      fullfilemtAt: fullfilemtAt,
      fromDate: valueFilterByFromDate,
      toDate: valueFilterByToDate,
      appName: filterApp,
      channelName: filterChannel,
      risk: filterRisk,
      return: filterReturn,
      orderStatus:customFilterOrderStatusArray,
      deliveryMethod:customFilterOrderDeliveryMethod

    };
    console.log("custom filter values", customFilterData);
    const res = await axios.post(`${baseURL}/createCustomFilters`, {
      customFilters: customFilterData,
    });
    console.log("res data", res.data);
    if (res.data.statusCode == 200) {
      swal({
        title: "Created",
        text: "Custom filter created successfully",
        icon: "success",
        button: "Done",
      });
      setcustomFilterCreated(!customFilterCreated);
      setvalueFilterByDate(0);
      window.location.reload();
    } else {
      swal({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        button: "Done",
      });
    }
  };
  const [customFilterSelectData, setcustomFilterSelectData] = useState([]);
  const [customFilterValue, setcustomFilterValue] = useState("All");

  const customFilterDataArraySelectChange = (value) => {
    console.log("Selected Custom filter value", value);
    setcustomFilterValue(value);
  };
  const options = [
    {
      label: "FULFILLED",
      value: "FULFILLED",
    },
    {
      label: "UNFULFILLED",
      value: "UNFULFILLED",
    },
    {
      label: "PARTIALLY_FULFILLED",
      value: "PARTIALLY_FULFILLED",
    },
    {
      label: "IN_PROGRESS",
      value: "IN_PROGRESS",
    },
    {
      label: "SCHEDULED",
      value: "SCHEDULED",
    },
    {
      label: "OPEN",
      value: "OPEN",
    },
    {
      label: "PENDING_FULFILLMENT",
      value: "PENDING_FULFILLMENT",
    },
    {
      label: "RESTOCKED",
      value: "RESTOCKED",
    },
    {
      label: "ON_HOLD",
      value: "ON_HOLD",
    },
  ];
  const optionsForOrderStatus = [
    {
      label: "OPEN",
      value: "OPEN",
    },
    {
      label: "CANCELLED",
      value: "CANCELLED",
    },
    {
      label: "ARCHIVE",
      value: "ARCHIVE",
    }
  ];
  const optionsForOrderDeliveryMethod = [
    {
      label: "LOCAL",
      value: "LOCAL",
    },
    {
      label: "NONE",
      value: "NONE",
    },
    {
      label: "PICK_UP",
      value: "PICK_UP",
    }
    ,
    {
      label: "RETAIL",
      value: "RETAIL",
    }
    ,
    {
      label: "SHIPPING",
      value: "SHIPPING",
    }
  ];
  const optionsDeliveryStatus = [
    {
      label: "ATTEMPTED_DELIVERY",
      value: "ATTEMPTED_DELIVERY",
    },
    {
      label: "CANCELED",
      value: "CANCELED",
    },
    {
      label: "CONFIRMED",
      value: "CONFIRMED",
    },
    {
      label: "DELIVERED",
      value: "DELIVERED",
    },
    {
      label: "FAILURE",
      value: "FAILURE",
    },
    {
      label: "FULFILLED",
      value: "FULFILLED",
    },
    {
      label: "IN_TRANSIT",
      value: "IN_TRANSIT",
    },
    {
      label: "LABEL_PRINTED",
      value: "LABEL_PRINTED",
    },
    {
      label: "LABEL_PURCHASED",
      value: "LABEL_PURCHASED",
    },
    {
      label: "LABEL_VOIDED",
      value: "LABEL_VOIDED",
    },
    {
      label: "MARKED_AS_FULFILLED",
      value: "MARKED_AS_FULFILLED",
    },
    {
      label: "NOT_DELIVERED",
      value: "NOT_DELIVERED",
    },
    {
      label: "OUT_FOR_DELIVERY",
      value: "OUT_FOR_DELIVERY",
    },
    {
      label: "PICKED_UP",
      value: "PICKED_UP",
    },
    {
      label: "READY_FOR_PICKUP",
      value: "READY_FOR_PICKUP",
    },
    {
      label: "SUBMITTED",
      value: "SUBMITTED",
    },
  ];
  const optionsFinancialStatus = [
    {
      label: "PENDING",
      value: "PENDING",
    },
    {
      label: "AUTHORIZED",
      value: "AUTHORIZED",
    },
    {
      label: "PAID",
      value: "PAID",
    },
    {
      label: "REFUNDED",
      value: "REFUNDED",
    },
    {
      label: "VOIDED",
      value: "VOIDED",
    },
    {
      label: "PARTIALLY_REFUNDED",
      value: "PARTIALLY_REFUNDED",
    },
    {
      label: "PARTIALLY_PAID",
      value: "PARTIALLY_PAID",
    },
    {
      label: "EXPIRED",
      value: "EXPIRED",
    },
  ];
  const [pickListGenerationType, setpickListGenerationType] = useState("");
  const [totalOrderLength, setTotalOrderLength] = useState(0);
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [sortByOrderId, setsortByOrderId] = useState(-1);
  const [sortByOrderIdValueToAPI, setsortByOrderIdValueToAPI] =
    useState("created_at");
  const [filterFulfillment, setfilterFulfillment] = useState("");
  const [filterDelivery, setfilterDelivery] = useState("");
  const [filterFinancial, setfilterFinancial] = useState("");

  const [valueFilterByFromDate, setvalueFilterByFromDate] = useState("");
  const [valueFilterByToDate, setvalueFilterByToDate] = useState("");
  const [valueFilterByDate, setvalueFilterByDate] = useState(0);
  const [openTags, setopenTags] = useState(false);
  const [openNotTags, setopenNotTags] = useState(false);
  const [openDeliveryDrawer, setopenDeliveryDrawer] = useState(false);
  const [openFinancialDrawer, setopenFinancialDrawer] = useState(false);

  const [openAppDrawer, setopenAppDrawer] = useState(false);
  const [openChannelDrawer, setopenChannelDrawer] = useState(false);
  const [openReturnDrawer, setopenReturnDrawer] = useState(false);
  const [openRiskDrawer, setopenRiskDrawer] = useState(false);

  const [clickOnTags, setclickOnTags] = useState(false);
  const [clickOnNotTags, setclickOnNotTags] = useState(false);

  const [clickOnApp, setclickOnApp] = useState(false);
  const [clickOnChannel, setclickOnChannel] = useState(false);
  const [clickOnReturn, setclickOnReturn] = useState(false);
  const [clickOnRisk, setclickOnRisk] = useState(false);

  const [filterTags, setfilterTags] = useState([]);
  const [filterApp, setfilterApp] = useState([]);
  const [filterChannel, setfilterChannel] = useState([]);

  const [filterReturn, setfilterReturn] = useState([]);
  const [filterRisk, setfilterRisk] = useState([]);

  const [filterNotTags, setfilterNotTags] = useState([]);
  const [fullfilemtAt, setfullfilemtAt] = useState("");

  const [clickedColumnName, setclickedColumnName] = useState("");
  const [clickedColumnNameOfScanOrderID, setclickedColumnNameOfScanOrderID] =
    useState("");
  const [scanOrderIDSort, setscanOrderIDSort] = useState(false);

  localStorage.setItem("page", page);
  localStorage.setItem("limit", limit);
  localStorage.setItem("sort", sortByOrderIdValueToAPI);
  localStorage.setItem("sortType", sortByOrderId);
  localStorage.setItem("fulfillmentStatusFilter", filterFulfillment);
  localStorage.setItem("fromDate", valueFilterByFromDate);
  localStorage.setItem("ToDate", valueFilterByToDate);
  localStorage.setItem("TagsValue", JSON.stringify(filterTags));
  localStorage.setItem("NotTagsValue", JSON.stringify(filterNotTags));
  localStorage.setItem("deliveryStatusFilter", filterDelivery);
  localStorage.setItem("financialStatusFilter", filterFinancial);
  localStorage.setItem("customFilterValue", customFilterValue);
  localStorage.setItem("fullfilemtAt", fullfilemtAt);
  localStorage.setItem("App", JSON.stringify(filterApp));
  localStorage.setItem("Channel", JSON.stringify(filterApp));
  localStorage.setItem("Return", JSON.stringify(filterReturn));
  localStorage.setItem("Risk", JSON.stringify(filterRisk));

  const showDrawerTags = () => {
    setopenTags(true);
  };
  const showDrawerNotTags = () => {
    setopenNotTags(true);
  };
  const showDrawerDelivery = () => {
    setopenDeliveryDrawer(true);
  };
  const showDrawerFinancial = () => {
    setopenFinancialDrawer(true);
  };
  const showDrawerApp = () => {
    setopenAppDrawer(true);
  };
  const showDrawerChannel = () => {
    setopenChannelDrawer(true);
  };
  const showDrawerReturn = () => {
    setopenReturnDrawer(true);
  };
  const showDrawerRisk = () => {
    setopenRiskDrawer(true);
  };
  const onCloseTags = () => {
    setopenTags(false);
  };
  const onCloseNotTags = () => {
    setopenNotTags(false);
  };

  const onCloseApp = () => {
    setopenAppDrawer(false);
  };
  const onCloseChannel = () => {
    setopenChannelDrawer(false);
  };
  const onCloseReturn = () => {
    setopenReturnDrawer(false);
  };
  const onCloseRisk = () => {
    setopenRiskDrawer(false);
  };
  const onChangeTags = (checkedValues) => {
    console.log("tags value = ", checkedValues);
    setfilterTags(checkedValues);
    localStorage.setItem("TagsValue", JSON.stringify(filterTags));
  };
  const onChangeApp = (checkedValues) => {
    console.log("tags value = ", checkedValues);
    setfilterApp(checkedValues);
    localStorage.setItem("App", JSON.stringify(filterApp));
  };
  const onChangeChannel = (checkedValues) => {
    console.log("tags value = ", checkedValues);
    setfilterChannel(checkedValues);
    localStorage.setItem("Channel", JSON.stringify(filterApp));
  };
  const onChangeReturnStatus = (checkedValues) => {
    console.log("tags value = ", checkedValues);
    setfilterReturn(checkedValues);
    localStorage.setItem("Return", JSON.stringify(filterReturn));
  };
  const onChangeRisk = (checkedValues) => {
    console.log("tags value = ", checkedValues);
    setfilterRisk(checkedValues);
    localStorage.setItem("Risk", JSON.stringify(filterRisk));
  };
  const onChangeNotTags = (checkedValues) => {
    console.log("tags value = ", checkedValues);
    setfilterNotTags(checkedValues);
    localStorage.setItem("NotTagsValue", JSON.stringify(filterTags));
  };
  const optionsTags = [
    {
      label: "WhatsApp_COD_Confirmed",
      value: "WhatsApp_COD_Confirmed",
    },
    {
      label: "WhatsApp_COD_Cancelled",
      value: "WhatsApp_COD_Cancelled",
    },
    {
      label: "WhatsApp_COD_ContactSupport",
      value: "WhatsApp_COD_ContactSupport",
    },
    {
      label: "vajro_mobile_app",
      value: "vajro_mobile_app",
    },
    {
      label: "android",
      value: "android",
    },
  ];
  const optionsApp = [
    {
      label: "Online Store",
      value: "Online Store",
    },
    {
      label: "android",
      value: "android",
    },
  ];
  const optionsChannel = [
    {
      label: "Online Store",
      value: "Online Store",
    },
    {
      label: "android",
      value: "android",
    },
  ];
  const optionsReturn = [
    {
      label: "INSPECTION_COMPLETE",
      value: "INSPECTION_COMPLETE",
    },
    {
      label: "IN_PROGRESS",
      value: "IN_PROGRESS",
    },
    {
      label: "NO_RETURN",
      value: "NO_RETURN",
    },
    {
      label: "RETURNED",
      value: "RETURNED",
    },
    {
      label: "RETURN_FAILED",
      value: "RETURN_FAILED",
    },
    {
      label: "RETURN_REQUESTED",
      value: "RETURN_REQUESTED",
    },
  ];
  const optionsRisk = [
    {
      label: "HIGH",
      value: "HIGH",
    },
    {
      label: "LOW",
      value: "LOW",
    },
    {
      label: "MEDIUM",
      value: "MEDIUM",
    },
  ];
  const onPaginationChange = (page, limit) => {
    setpage(page);
    setlimit(limit);
    localStorage.setItem("page", page);
    localStorage.setItem("limit", limit);

    console.log("Page-", page, " Limit-", limit);
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [category, setcategory] = useState("-1");
  const [categoryName, setcategoryName] = useState("All Orders");
  const [staffArray, setstaffArray] = useState([]);
  const [staffArrayForAllOrder, setstaffArrayForAllOrder] = useState({});
  const [staffArrayForAllOrderList, setstaffArrayForAllOrderList] = useState(
    []
  );
  const [mainOperator, setmainOperator] = useState("AND");

  const [Expression, setExpression] = useState([]);

  const [orderFieldsArray, setorderFieldsArray] = useState([
    "name",
    "total_price",
    "created_at",
    "displayFulfillmentStatus",
  ]);
  const [checkedOrderArray, setcheckedOrderArray] = useState([]);
  const [callmagicassignexpression, setcallmagicassignexpression] =
    useState(false);

  const [checkedAllOrderArray, setcheckedAllOrderArray] = useState([]);
  const [magicAssignFieldData, setmagicAssignFieldData] = useState("");
  const [magicAssignOperatorData, setmagicAssignOperatorData] = useState("");
  const [magicAssignValueData, setmagicAssignValueData] = useState("");
  const [magicAssignFieldData1, setmagicAssignFieldData1] = useState("");
  const [magicAssignOperatorData1, setmagicAssignOperatorData1] = useState("");
  const [magicAssignValueData1, setmagicAssignValueData1] = useState("");
  const [magicAssignFieldData2, setmagicAssignFieldData2] = useState("");
  const [magicAssignOperatorData2, setmagicAssignOperatorData2] = useState("");
  const [magicAssignValueData2, setmagicAssignValueData2] = useState("");
  const [magicAssignFieldData3, setmagicAssignFieldData3] = useState("");
  const [magicAssignOperatorData3, setmagicAssignOperatorData3] = useState("");
  const [magicAssignValueData3, setmagicAssignValueData3] = useState("");
  const [magicAssignFieldData4, setmagicAssignFieldData4] = useState("");
  const [magicAssignOperatorData4, setmagicAssignOperatorData4] = useState("");
  const [magicAssignValueData4, setmagicAssignValueData4] = useState("");
  const [magicAssignFieldData5, setmagicAssignFieldData5] = useState("");
  const [magicAssignOperatorData5, setmagicAssignOperatorData5] = useState("");
  const [magicAssignValueData5, setmagicAssignValueData5] = useState("");
  const [NumberMagicAssign, setNumberMagicAssign] = useState([1]);
  const [MultimagicAssignExp, setMultimagicAssignExp] = useState({});
  const [open, setOpen] = useState(false);
  const [clickOnDateFilter, setclickOnDateFilter] = useState(false);
  const [clickOnFulfimentFilter, setclickOnFulfilmentFilter] = useState(false);
  const [clickOnOrderStatus, setclickOnOrderStatus] = useState(false);
  const [clickOnDeliveryMethod, setclickOnDeliveryMethod] = useState(false);


  const [clickOnDeliveryFilter, setclickOnDeliveryFilter] = useState(false);
  const [clickOnFinancialFilter, setclickOnFinancialFilter] = useState(false);
  const [staffArrayPopup, setstaffArrayPopup] = useState([]);


  const onChangeSortFullfilled = (e) => {
    console.log(`value- ${e.target.value}`);
    setfilterFulfillment(e.target.value);
    localStorage.setItem("fulfillmentStatusFilter", filterFulfillment);
  };
  const onChangeDeliveryStatus = (e) => {
    console.log(`value- ${e.target.value}`);
    setfilterDelivery(e.target.value);
    localStorage.setItem("deliveryStatusFilter", filterDelivery);
  };
  const onChangeFinancialStatus = (e) => {
    console.log(`value- ${e.target.value}`);
    setfilterFinancial(e.target.value);
    localStorage.setItem("financialStatusFilter", filterFinancial);
  };
  const onChangeCustomDate = (x, y) => {
    console.log("x ", x, " y", y);
    console.log("x ", x[0]);
    setvalueFilterByToDate(y[1]);
    setvalueFilterByFromDate(y[0]);
    setEditFromDate(y[0]);
    setEditToDate(y[1]);
    localStorage.setItem("fromDate", valueFilterByFromDate);
    localStorage.setItem("ToDate", valueFilterByToDate);
    localStorage.setItem("fromDate", editFromData);
    localStorage.setItem("ToDate", editToData);
  };
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setvalueFilterByDate(e.target.value);
    if (e.target.value == 1) {
      let d = moment(new Date()).format(
        "YYYY-MM-DD"
      );
      console.log("Today is: " + d);
      setvalueFilterByToDate(d);
      setEditToDate(d);
      let d1=moment(new Date()).subtract(1,'d').format(
        "YYYY-MM-DD"
      )
      console.log("1 days ago was: " + d1);
      setvalueFilterByFromDate(d1);
      setEditFromDate(d1);
      localStorage.setItem("fromDate", valueFilterByFromDate);
      localStorage.setItem("ToDate", valueFilterByToDate);
      localStorage.setItem("fromDate", editFromData);
      localStorage.setItem("ToDate", editToData);
    } else if (e.target.value == 2) {
      let d = moment(new Date()).format(
        "YYYY-MM-DD"
      );
      console.log("Today is: " + d);
      setvalueFilterByToDate(d);
      setEditToDate(d);
      let d1=moment(new Date()).subtract(7,'d').format(
        "YYYY-MM-DD"
      )
      console.log("1 days ago was: " + d1);
      setvalueFilterByFromDate(d1);
      setEditFromDate(d1);
      localStorage.setItem("fromDate", valueFilterByFromDate);
      localStorage.setItem("ToDate", valueFilterByToDate);
      localStorage.setItem("fromDate", editFromData);
      localStorage.setItem("ToDate", editToData);
    } else if (e.target.value == 3) {
      let d = moment(new Date()).format(
        "YYYY-MM-DD"
      );
      console.log("Today is: " + d);
      setvalueFilterByToDate(d);
      setEditToDate(d);
      let d1=moment(new Date()).subtract(30,'d').format(
        "YYYY-MM-DD"
      )
      console.log("1 days ago was: " + d1);
      setvalueFilterByFromDate(d1);
      setEditFromDate(d1);
      localStorage.setItem("fromDate", valueFilterByFromDate);
      localStorage.setItem("ToDate", valueFilterByToDate);
      localStorage.setItem("fromDate", editFromData);
      localStorage.setItem("ToDate", editToData);
    } else if (e.target.value == 4) {
      let d = moment(new Date()).format(
        "YYYY-MM-DD"
      );
      console.log("Today is: " + d);
      setvalueFilterByToDate(d);
      setEditToDate(d);
      let d1=moment(new Date()).subtract(90,'d').format(
        "YYYY-MM-DD"
      )
      console.log("1 days ago was: " + d1);
      setvalueFilterByFromDate(d1);
      setEditFromDate(d1);
      localStorage.setItem("fromDate", valueFilterByFromDate);
      localStorage.setItem("ToDate", valueFilterByToDate);
      localStorage.setItem("fromDate", editFromData);
      localStorage.setItem("ToDate", editToData);
    } else if (e.target.value == 5) {
      let d = moment(new Date()).format(
        "YYYY-MM-DD"
      );
      console.log("Today is: " + d);
      setvalueFilterByToDate(d);
      setEditToDate(d);
      let d1=moment(new Date()).subtract(365,'d').format(
        "YYYY-MM-DD"
      )
      console.log("1 days ago was: " + d1);
      setEditFromDate(d1);
      console.log("365 days ago was: " + d1);
      localStorage.setItem("fromDate", valueFilterByFromDate);
      localStorage.setItem("ToDate", valueFilterByToDate);
      localStorage.setItem("fromDate", editFromData);
      localStorage.setItem("ToDate", editToData);
    }
  };
  const onChangeFulfilledAt = (e, date) => {
    console.log("Date Change", e);
    console.log("Date Change", date);
    setfullfilemtAt(date);
    setEditFulfilledAt(date);
    localStorage.setItem("fullfilemtAt", fullfilemtAt);
    localStorage.setItem("fullfilemtAt", editfulfilledAt);
    localStorage.setItem("fromDate", editFromData);
    localStorage.setItem("ToDate", editToData);
  };
  const onOk = (value) => {
    console.log("onOk: ", value);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [openFullfillment, setopenFullfillment] = useState(false);
  const [openDeliveryMethod, setopenDeliveryMethod] = useState(false);

  const [openOrderStatus, setopenOrderStatus] = useState(false);

  const showDrawerFullfillment = () => {
    setopenFullfillment(true);
  };
  const showDrawerDeliveryMethod = () => {
    setopenDeliveryMethod(true);
  };
  const showDrawerOrderStatus = () => {
    setopenOrderStatus(true);
  };
  const onCloseFullfillment = () => {
    setopenFullfillment(false);
  };
  const onCloseDeliveryMethod = () => {
    setopenDeliveryMethod(false);
  };
  const onCloseOrderStatus = () => {
    setopenOrderStatus(false);
  };
  const onCloseDeliveryStatus = () => {
    setopenDeliveryDrawer(false);
  };
  const onCloseFinancialStatus = () => {
    setopenFinancialDrawer(false);
  };
  const onHandleMultiMagiCExpField = (value, i) => {
    if (i == 0) {
      setmagicAssignFieldData1(value);
      localStorage.setItem("magicAssignFieldData1", value);
    }
    if (i == 1) {
      setmagicAssignFieldData2(value);
      localStorage.setItem("magicAssignFieldData2", value);
    }
    if (i == 2) {
      setmagicAssignFieldData3(value);
      localStorage.setItem("magicAssignFieldData3", value);
    }
    if (i == 3) {
      setmagicAssignFieldData4(value);
    }
    if (i == 4) {
      setmagicAssignFieldData5(value);
    }
    console.log("field", value, i);
  };
  const onHandleMultiMagiCExpOperator = (value, i) => {
    if (i == 0) {
      setmagicAssignOperatorData1(value);
      localStorage.setItem("magicAssignOperatorData1", value);
    }
    if (i == 1) {
      setmagicAssignOperatorData2(value);
      localStorage.setItem("magicAssignOperatorData2", value);
    }
    if (i == 2) {
      setmagicAssignOperatorData3(value);
      localStorage.setItem("magicAssignOperatorData3", value);
    }
    if (i == 3) {
      setmagicAssignOperatorData4(value);
    }
    if (i == 4) {
      setmagicAssignOperatorData5(value);
    }
    console.log("exp", value, i);
  };
  const onHandleMultiMagiCExpValue = (value, i) => {
    if (i == 0) {
      setmagicAssignValueData1(value);
      localStorage.setItem("magicAssignValueData1", value);
    }
    if (i == 1) {
      setmagicAssignValueData2(value);
      localStorage.setItem("magicAssignValueData2", value);
    }
    if (i == 2) {
      setmagicAssignValueData3(value);
      localStorage.setItem("magicAssignValueData3", value);
    }
    if (i == 3) {
      setmagicAssignValueData4(value);
    }
    if (i == 4) {
      setmagicAssignValueData5(value);
    }
    console.log("value", value, i);
  };

  const [magicAssignFields, setmagicAssignFields] = useState("");
  const [pickerPackerID, setpickerPackerID] = useState("");
  const [pickerPackerFirstName, setpickerPackerFirstName] = useState("");
  const [count, setCount] = useState([]);
  const [scanCount, setScanCount] = useState([]);
  const [AssignName, setAssignName] = useState("Unassigned");
  const [magicSwitch, setmagicSwitch] = useState("");
  const [tickAll, settickAll] = useState(false);
  const [tickAllScan, settickAllScan] = useState(false);

  const [refresh, setrefresh] = useState(true);
  const [sortByOrderAmount, setsortByOrderAmount] = useState(true);
  const [sortByOrderStatus, setsortByOrderStatus] = useState(true);
  const [taxes_included, settaxes_included] = useState(false);
  const [Total_tax, setTotal_tax] = useState(false);
  const [Total_tip_received, setTotal_tip_received] = useState(false);
  const [Order_Price, setOrder_Price] = useState(true);
  const [Total_discounts, setTotal_discounts] = useState(false);
  const [Tags, setTags] = useState(false);
  const [Order_ID, setOrder_ID] = useState(true);
  const [Order_Number, setOrder_Number] = useState(false);
  const [Gateway, setGateway] = useState(false);
  const [Order_Fulfillment, setOrder_Fulfillment] = useState(true);
  const [Order_Status, setOrder_Status] = useState(true);
  const [Currency, setCurrency] = useState(false);
  const [orderIndicator, setorderIndicator] = useState(true);
  const [orderPriceIndicator, setorderPriceIndicator] = useState(true);
  const [Contact_email, setContact_email] = useState(false);
  const [Total_weight, setTotal_weight] = useState(false);

  const [show, setShow] = useState(false);
  const [showAssign, setShowAssign] = useState(false);
  const [showAssignNew, setShowAssignNew] = useState(false);
  const [showAssignMagic, setshowAssignMagic] = useState(false);

  const [searchInput, setSearchInput] = useState("1");
  const [filteredResults, setFilteredResults] = useState([]);
  const [initialTime, setinitialTime] = useState("");
  const [showTime, setshowTime] = useState(0);
  const [autoRefresh, setautoRefresh] = useState(false);
  const [pickListAssignUserName, setpickListAssignUserName] = useState("");
  const [pickListAssignUserID, setpickListAssignUserID] = useState("");



  const handlePickListAssignOrder=(value,label)=>{
    console.log("value",value,label)
    setpickListAssignUserName(label.label)
    setpickListAssignUserID(label.value)
  }

  const handleAllUnassign = () => {
    setrefresh(false);
    axios
      .post(`${baseURL}/changeinorderAll`, {
        shopName: localStorage.getItem("shop"),
        shopCode: localStorage.getItem("code"),
        type: "unassigned",
        checkedOrderArray: checkedOrderArray,
      })
      .then((res) => {
        if (res.data.statusCode == 200) {
          setrefresh(true);
          swal({
            title: "Order Unassigned",
            text: "Selected order unassigned",
            icon: "success",
            button: "Done",
          });
          clickOnRefresh();
        } else {
          setrefresh(true);
          swal({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            button: "Done",
          });
          clickOnRefresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleAllToSpec = () => {
    setrefresh(false);
    setShowAssignNew(false);
    axios
      .post(`${baseURL}/changeinorderAll`, {
        shopName: localStorage.getItem("shop"),
        shopCode: localStorage.getItem("code"),
        type: "toOther",
        checkedOrderArray: checkedOrderArray,
        toAssign: pickerPackerID,
        assignToName: pickerPackerFirstName,
        assignBy: localStorage.getItem("staffName").split(" ")[0],
      })
      .then((res) => {
        if (res.data.statusCode == 200) {
          setrefresh(true);
          swal({
            title: "Order Assigned",
            text: "Selected order assigned",
            icon: "success",
            button: "Done",
          });
          clickOnRefresh();
        } else {
          setrefresh(true);
          swal({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            button: "Done",
          });
          clickOnRefresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowAssign = () => {
    //setShowAssign(true);
    if (checkedOrderArray.length > 0) {
      setShowAssign(true);
    } else {
      swal({
        title: "Warning!",
        text: "Please select one or multiple orders",
        icon: "warning",
        button: "Done",
      });
    }
  };
  const generatePickListAndAssign=()=>{
   setrefreshModal(false);
    axios
      .post(`${baseURL}/assignOrderToSpecificPerson`, {
        shop: localStorage.getItem("shop"),
        shopCode: localStorage.getItem("code"),
        orderIdArray: checkedOrderArray,
        personId: pickListAssignUserID,
        assignToName: pickListAssignUserName.split(" ")[0],
        assignBy: localStorage.getItem("staffName").split(" ")[0],
      })
      .then(async(res) => {
        if (res.data.statusCode == 200) {
          var res1 = await axios.post(`${baseURL}/getProductPickList`, {
            shop: localStorage.getItem("shop"),
            shopCode: localStorage.getItem("code"),
            orderIdArray: checkedOrderArray,
            assignBy: localStorage.getItem("staffName").split(" ")[0],
          });
          console.log("Pick List Data", res1.data);
         /*  const jsonData = encodeURIComponent(JSON.stringify(checkedOrderArray));
          const url = `/pickListPage?data=${jsonData}`;
          const newTab = window.open(url, '_blank');
          if (newTab) {
            newTab.focus();
          } else {
            console.error('Failed to open new tab');
          } */
          setrefreshModal(true);

          /* swal({
            title: "Pick List",
            text: "PickList generated successfully",
            icon: "success",
            button: "Done",
          }); */
          navigate("/pickListPage", {
            replace: false,
            state: { completeOrderList: res1.data.data }
          });
          swal({
            title: "Orders Assigned and Picklist Generated",
            text: "Orders have been assigned and picklist generated successfully.",
            icon: "success",
            button: "Done",
          });
        } else {
          swal({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            button: "Done",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const handleGeneratePickListPopUp = async () => {
    setShowAssign(true);
    if (checkedOrderArray.length > 0) {
      var res = await axios.post(`${baseURL}/getProductPickList`, {
        shop: localStorage.getItem("shop"),
        shopCode: localStorage.getItem("code"),
        orderIdArray: checkedOrderArray,
        assignBy: localStorage.getItem("staffName").split(" ")[0],
      }); 
      console.log("Pick List Data", res.data);
      swal({
        title: "Pick List",
        text: "PickList generated successfully",
        icon: "success",
        button: "Done",
      });
      /* const jsonData = encodeURIComponent(JSON.stringify(checkedOrderArray));
    const url = `/pickListPage?data=${jsonData}`;
    const newTab = window.open(url, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      console.error('Failed to open new tab');
    } */
      navigate("/pickListPage", {
        replace: false,
        state: { completeOrderList: res.data.data },
      });
    } else {
      swal({
        title: "Warning!",
        text: "Please select one or multiple orders",
        icon: "warning",
        button: "Done",
      });
    }
  };
  const handleGeneratePickList = async () => {
    //setShowAssign(true);
    if (checkedOrderArray.length > 0) {
      /* setOpenAntModalPickListPopup(true) */
      /* navigate("/PickListLandingPage", {
        replace: false,
        state: { checkedOrderArray: checkedOrderArray,staffArrayPopup:staffArrayPopup },
      }); */
      const jsonData1 = encodeURIComponent(JSON.stringify(checkedOrderArray));
      const jsonData2 = encodeURIComponent(JSON.stringify(staffArrayPopup));

    const url = `/PickListLandingPage?checkedOrderArray=${jsonData1}&staffArrayPopup=${jsonData2}`;
    const newTab = window.open(url, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      console.error('Failed to open new tab');
    } 
     /*  var res = await axios.post(`${baseURL}/getProductPickList`, {
        shop: localStorage.getItem("shop"),
        shopCode: localStorage.getItem("code"),
        orderIdArray: checkedOrderArray,
        assignBy: localStorage.getItem("staffName").split(" ")[0],
      });
      console.log("Pick List Data", res.data);
      swal({
        title: "Pick List",
        text: "PickList generated successfully",
        icon: "success",
        button: "Done",
      });
      navigate("/pickListPage", {
        replace: false,
        state: { completeOrderList: res.data.data },
      }); */
    } else {
      swal({
        title: "Warning!",
        text: "Please select one or multiple orders",
        icon: "warning",
        button: "Done",
      });
    }
  };
  const handleGeneratePickListByProduct = async () => {
    //setShowAssign(true);
    if (checkedOrderArray.length > 0) {
      var res = await axios.post(
        `${baseURL}/getProductPickListByBatchingWithProduct`,
        {
          shop: localStorage.getItem("shop"),
          shopCode: localStorage.getItem("code"),
          orderIdArray: checkedOrderArray,
          assignBy: localStorage.getItem("staffName").split(" ")[0],
        }
      );
      console.log("Pick List Data", res.data);
      swal({
        title: "Pick List",
        text: "PickList generated successfully",
        icon: "success",
        button: "Done",
      });
      navigate("/pickListByProductPage", {
        replace: false,
        state: { completeOrderList: res.data.data },
      });
    } else {
      swal({
        title: "Warning!",
        text: "Please select one or multiple orders",
        icon: "warning",
        button: "Done",
      });
    }
  };
  const handleShowAssignNew = () => {
    //setShowAssign(true);
    if (checkedOrderArray.length > 0) {
      setShowAssignNew(true);
    } else {
      swal({
        title: "Warning!",
        text: "Please select one or multiple orders",
        icon: "warning",
        button: "Done",
      });
    }
  };
  const handleShowMagic = () => setshowAssignMagic(true);

  const handleCloseAssgn = () => setShowAssign(false);
  const handleCloseAssgnNew = () => setShowAssignNew(false);
  const handleCloseMagic = () => setshowAssignMagic(false);

  var d;
  const handleTickAll = () => {
    if (checkedAllOrderArray.length == 0) {
      var newArr = [];
      count.forEach((item) => {
        newArr.push(item.name);
      });
      setcheckedOrderArray(newArr);
      setcheckedAllOrderArray(newArr);
      settickAll(true);
    } else {
      setcheckedOrderArray([]);
      setcheckedAllOrderArray([]);

      settickAll(false);
    }
  };
  const handleTickAllScanTable = () => {
    if (checkedAllOrderArray.length == 0) {
      var newArr = [];
      scanCount.forEach((item) => {
        newArr.push(item.name);
      });
      setcheckedOrderArray(newArr);
      setcheckedAllOrderArray(newArr);
      settickAllScan(true);
    } else {
      setcheckedOrderArray([]);
      setcheckedAllOrderArray([]);

      settickAllScan(false);
    }
  };
  const clickOnAddExpression = () => {
    var no = NumberMagicAssign;
    var obj = {
      field: "",
      operator: "",
      value: "",
    };
    setNumberMagicAssign([...NumberMagicAssign, obj]);
    console.log("Expression No", NumberMagicAssign);
  };

  setInterval(() => {
    d = new Date();
    let minutes = d.getMinutes();
    setshowTime(
      parseInt(minutes) - parseInt(initialTime) > 0
        ? parseInt(minutes) - parseInt(initialTime)
        : 60 + parseInt(minutes) - parseInt(initialTime)
    );
  }, 1000 * 60);
  const handleApply = () => {
    setShow(false);
    setrefresh(false);
    setTimeout(() => {
      setrefresh(true);
    }, 500);
  };
  const handleMagicApply = () => {
    setshowAssignMagic(false);
    setrefresh(false);
    axios
      .post(`${baseURL}/onOffMagicAssignUpdateExpression`, {
        shopName: localStorage.getItem("shop"),
        shopCode: localStorage.getItem("code"),
        expression:
          magicAssignFieldData +
          "," +
          magicAssignOperatorData +
          "," +
          magicAssignValueData,
      })
      .then((res) => {
        if (res.data.statusCode == 200) {
          setrefresh(true);
          swal({
            title: "Magic Assign",
            text: "Magic Assign Set",
            icon: "success",
            button: "Done",
          });
          clickOnRefresh();
        } else {
          setrefresh(true);
          swal({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            button: "Done",
          });
          clickOnRefresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const removeExp = () => {
    var temp = NumberMagicAssign;
    temp.pop();
    setNumberMagicAssign(temp);
    setrefresh(false);
    setTimeout(() => {
      setrefresh(true);
    }, 250);
  };

  const handleMagicApplyMulti = () => {
    setshowAssignMagic(false);
    setrefresh(false);
    var completeExp = "";
    var count = NumberMagicAssign.length;
    completeExp =
      NumberMagicAssign.length.toString() +
      "|" +
      "" +
      localStorage.getItem("magicAssignFieldData1") +
      "," +
      localStorage.getItem("magicAssignOperatorData1") +
      "," +
      localStorage.getItem("magicAssignValueData1") +
      "|" +
      "" +
      localStorage.getItem("magicAssignFieldData2") +
      "," +
      localStorage.getItem("magicAssignOperatorData2") +
      "," +
      localStorage.getItem("magicAssignValueData2") +
      "|" +
      "" +
      localStorage.getItem("magicAssignFieldData3") +
      "," +
      localStorage.getItem("magicAssignOperatorData3") +
      "," +
      localStorage.getItem("magicAssignValueData3");

    console.log("MultiAssignExo", completeExp);

    axios
      .post(`${baseURL}/onOffMagicAssignUpdateExpression`, {
        shopName: localStorage.getItem("shop"),
        shopCode: localStorage.getItem("code"),
        expression: completeExp,
        operator: mainOperator,
      })
      .then((res) => {
        if (res.data.statusCode == 200) {
          setrefresh(true);
          swal({
            title: "Magic Assign",
            text: "Magic Assign Set",
            icon: "success",
            button: "Done",
          });
          setcallmagicassignexpression(!callmagicassignexpression);

          clickOnRefresh();
        } else {
          setrefresh(true);
          swal({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            button: "Done",
          });
          clickOnRefresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const increaseOrderCount = () => {
    setShowAssign(false);
    setrefresh(false);
    axios
      .post(`${baseURL}/increaseOrderAssignCount`, {
        shop: localStorage.getItem("shop"),
        orderIdArray: checkedOrderArray,
        personId: pickerPackerID,
      })
      .then((res) => {
        if (res.data.statusCode == 200) {
          setrefresh(true);
        } else {
          setrefresh(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const decreaseOrderCount = (id) => {
    setShowAssign(false);
    setrefresh(false);
    axios
      .post(`${baseURL}/decreaseOrderAssignCount`, {
        shop: localStorage.getItem("shop"),
        orderIdArray: checkedOrderArray,
        personId: id,
      })
      .then((res) => {
        if (res.data.statusCode == 200) {
          setrefresh(true);
        } else {
          setrefresh(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleApplyAssign = () => {
    setShowAssign(false);
    setrefresh(false);
    axios
      .post(`${baseURL}/assignOrderToSpecificPerson`, {
        shop: localStorage.getItem("shop"),
        shopCode: localStorage.getItem("code"),
        orderIdArray: checkedOrderArray,
        personId: pickerPackerID,
        assignToName: pickerPackerFirstName,
        assignBy: localStorage.getItem("staffName").split(" ")[0],
      })
      .then((res) => {
        if (res.data.statusCode == 200) {
          setrefresh(true);
          if (category == "0") {
            increaseOrderCount();
          } else {
            if (category != pickerPackerID) {
              increaseOrderCount();
              decreaseOrderCount(category);
            }
          }

          swal({
            title: "Orders Assigned",
            text: "Orders have been assigned successfully.",
            icon: "success",
            button: "Done",
          });
          clickOnRefresh();
        } else {
          setrefresh(true);
          swal({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            button: "Done",
          });
          clickOnRefresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleApplyAutoAssign = () => {
    setrefresh(false);
    if (checkedOrderArray.length > 0) {
      axios
        .post(`${baseURL}/autoAssign`, {
          shop: localStorage.getItem("shop"),
          orderIdArray: checkedOrderArray,
        })
        .then((res) => {
          if (res.data.statusCode == 200) {
            setrefresh(true);
            swal({
              title: "Orders Assigned",
              text: "Orders have been auto assigned successfully.",
              icon: "success",
              button: "Done",
            });
            clickOnRefresh();
          } else {
            setrefresh(true);
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              button: "Done",
            });
            clickOnRefresh();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setrefresh(true);
      swal({
        title: "Warning!",
        text: "Please select one or multiple orders",
        icon: "warning",
        button: "Done",
      });
    }
  };
  const handleMagicAssign = () => {
    setrefresh(false);
    axios
      .post(`${baseURL}/onOffMagicAssign`, {
        shopName: localStorage.getItem("shop"),
        shopCode: localStorage.getItem("code"),
        switchOnOff: !magicSwitch ? 1 : 0,
      })
      .then((res) => {
        if (res.data.statusCode == 200) {
          setrefresh(true);
          swal({
            title: "Magic Assign",
            text: "Magic Assign Set",
            icon: "success",
            button: "Done",
          });
          clickOnRefresh();
        } else {
          setrefresh(true);
          swal({
            title: "Error",
            text: res.data.message,
            icon: "error",
            button: "Done",
          });
          clickOnRefresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleApplyUnAssign = () => {
    setrefresh(false);
    if (checkedOrderArray.length > 0) {
      axios
        .post(`${baseURL}/assignOrderToSpecificPerson`, {
          shop: localStorage.getItem("shop"),
          shopCode: localStorage.getItem("code"),
          orderIdArray: checkedOrderArray,
          personId: 0,
        })
        .then((res) => {
          if (res.data.statusCode == 200) {
            setrefresh(true);
            decreaseOrderCount(category);
            swal({
              title: "Orders Unassigned!",
              text: "Selected Orders have been unassigned.",
              icon: "success",
              button: "Done",
            });
            clickOnRefresh();
          } else {
            setrefresh(true);
            swal({
              title: "Error",
              text: "Something went wrong",
              icon: "error",
              button: "Done",
            });
            clickOnRefresh();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setrefresh(true);
      swal({
        title: "Warning!",
        text: "Please select one or multiple orders",
        icon: "warning",
        button: "Done",
      });
    }
  };
  const handleApplyOrderFields = () => {
    setrefresh(false);
    setShow(false);
    axios
      .post(`${baseURL}/updateOrderFields`, {
        shop: localStorage.getItem("shop"),
        orderFields: orderFieldsArray,
        id: localStorage.getItem("staffId"),
      })
      .then((res) => {
        if (res.data.statusCode == 200) {
          setrefresh(true);
          swal({
            title: "Good job!",
            text: "You have set your order fields",
            icon: "success",
            button: "Done",
          });
          setorderFieldsArray(res.data.data.order_fields.split(","));
          localStorage.setItem("orderFields", res.data.data.order_fields);
          clickOnRefresh();
        } else {
          setrefresh(true);
          swal({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            button: "Done",
          });
          clickOnRefresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [orderSerach, setorderSerach] = useState("");
  async function searchItems() {
    console.log("Hit");
    const searchValue = orderSerach;
    /*  setSearchInput(searchValue); */
    setrefresh(false);
    if (orderSerach != "") {
      /* const filteredData = count.filter((item) => {
        return Object.values(item).join("").toLowerCase().includes(searchValue);
      });
      console.log(filteredData);

      setFilteredResults(filteredData); */
      axios
        .get(
          `${baseURL}/orderListFetchOne?shop=${localStorage.getItem(
            "shop"
          )}&code=${localStorage.getItem("code")}&orderID=${searchValue}`
        )
        .then((res) => {
          setrefresh(true);
          if (res.data.orders.length > 0) {
            /* var scanOrderArray=[]
            scanOrderArray=scanCount
            scanOrderArray.push(res.data.orders) */
            //setstaffArrayForAllOrder(assineeObject);
        var mainOrder = res.data.orders[0];
     
        mainOrder.Assigned_To =
        res.data.orderAssigneeID[0] == "0"
              ? "Unassigned"
              : staffArrayForAllOrder[res.data.orderAssigneeID[0]];
 
            setScanCount([...scanCount, mainOrder]);
            console.log("Important",mainOrder)

            handleOrderCheckBox(1, res.data.orders[0].name);
            /* setstaffArrayForAllOrderList([staff]res.data.orderAssigneeID); */
            setorderSerach("");
          } else {
            swal({
              title: "Order not found",
              text: "Please check the order number",
              icon: "error",
              button: "Done",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setrefresh(true);
      /* setFilteredResults(count); */
    }
  }

  /*  useEffect(() => {
     localStorage.setItem("category1", "0");
     const interval = setInterval(() => {
       console.log("Updated category Updated 3");
 
       console.log("Updated");
       console.log("Page-",page," limit-",limit)
       setrefresh(false);
       axios
         .get(
           `${baseURL}/orderList?shop=${localStorage.getItem(
             "shop"
           )}&code=${localStorage.getItem(
             "code"
           )}&assignType=${localStorage.getItem("category1")}&page=${localStorage.getItem("page")}&limit=${localStorage.getItem("limit")}&sort=orderDetail.${localStorage.getItem("sort")}&sortType=${localStorage.getItem("sortType")}&fulfillmentFilter=${localStorage.getItem("fulfillmentStatusFilter")}&fromDate=${localStorage.getItem("fromDate")}&ToDate=${localStorage.getItem("ToDate")}&tags=${localStorage.getItem("TagsValue")}`
         )
         .then((res) => {
           console.log("Response data", res.data.orders);
           setstaffArrayForAllOrderList(res.data.orderAssigneeID);
           setTotalOrderLength(res.data.TotalOrderLength)
           var assineeObject = {};
           res.data.staffArray.forEach((item) => {
             assineeObject[item._id] = item.first_name + " " + item.last_name;
           });
           setstaffArrayForAllOrder(assineeObject);
           var mainOrder = res.data.orders;
           mainOrder.forEach((item, i) => {
             item.Assigned_To =
               res.data.orderAssigneeID[i] == "0"
                 ? "Unassigned"
                 : assineeObject[res.data.orderAssigneeID[i]];
           });
           console.log("mainOrder", mainOrder);
           setCount(mainOrder);
           setstaffArray(res.data.staffArray);
           localStorage.setItem("staffArray",JSON.stringify(res.data.staffArray))
           console.log("staffArrayForAllOrder", staffArrayForAllOrder);
           var orderFields = res.data.orderFields.order_fields.split(",");
           setorderFieldsArray(
             localStorage.getItem("orderFields") == undefined
               ? "order_number,total_price,financial_status,fulfillment_status,id,app_id,tags,updated_at".split(
                   ","
                 )
               : localStorage.getItem("orderFields").split(",")
           );
 
           setcheckedOrderArray([]);
           res.data.magicAssign.isOn == 1
             ? setmagicSwitch(true)
             : setmagicSwitch(false);
           console.log("On/off", res.data.magicAssign.isOn);
           setcheckedAllOrderArray([]);
           setrefresh(true);
         })
         .catch((err) => {
           console.log(err);
         });
     }, 1000 * 60);
     return () => clearInterval(interval);
   }, [sortByOrderId]); */
  useEffect(() => {
    console.log("Updated 2");
    /* setrefresh(false); */
    /* localStorage.setItem("shop", shop);
    localStorage.setItem("code", code); */

    axios
      .get(
        `${baseURL}/getMagicOrderFields?shop=${localStorage.getItem(
          "shop"
        )}&code=${localStorage.getItem("code")}&assignType=${category}`
      )
      .then((res) => {
        console.log("Response data", res.data.orderFields);
        setmagicAssignFields(res.data.orderFields[0]);
        /*  setrefresh(true); */
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    var d1 = new Date();
    setinitialTime(d1.getMinutes());
    console.log("Updated 11");
    /*  setrefresh(false); */
    /* localStorage.setItem("shop", shop);
    localStorage.setItem("code", code); */

    axios
      .get(
        `${baseURL}/getExpressions?shop=${localStorage.getItem(
          "shop"
        )}&code=${localStorage.getItem("code")}&assignType=${category}`
      )
      .then((res) => {
        //console.log("Response data", res.data.expression);
        /* setExpression(res.data.expression);
        setmagicAssignFieldData(res.data.expression[0]);
        setmagicAssignOperatorData(res.data.expression[1]);
        setmagicAssignValueData(res.data.expression[2]);
 */

        var expression = res.data.expression;
        setmainOperator(res.data.operator);

        var expressionArray = expression.split("|");
        var count = parseInt(expressionArray[0]);
        var mainArray = [];

        for (var i = 1; i <= count; i++) {
          var obj = {};
          var exp = expressionArray[i].split(",");
          obj.field = exp[0];
          obj.operator = exp[1];
          obj.value = exp[2];
          mainArray.push(obj);

          localStorage.setItem(`magicAssignFieldData${i}`, exp[0]);
          localStorage.setItem(`magicAssignOperatorData${i}`, exp[1]);
          localStorage.setItem(`magicAssignValueData${i}`, exp[2]);
        }
        setNumberMagicAssign(mainArray);
        /*  setrefresh(true); */
      })
      .catch((err) => {
        console.log(err);
      });
  }, [callmagicassignexpression]);

  useEffect(() => {
    console.log("category update22222222");
    setrefresh(false);
    axios
      .get(
        `${baseURL}/orderList?shop=${localStorage.getItem(
          "shop"
        )}&code=${localStorage.getItem(
          "code"
        )}&assignType=${category}&page=${localStorage.getItem(
          "page"
        )}&limit=${localStorage.getItem(
          "limit"
        )}&sort=orderDetail.${localStorage.getItem(
          "sort"
        )}&sortType=${localStorage.getItem(
          "sortType"
        )}&fulfillmentFilter=${localStorage.getItem(
          "fulfillmentStatusFilter"
        )}&fromDate=${localStorage.getItem(
          "fromDate"
        )}&ToDate=${localStorage.getItem("ToDate")}&tags=${localStorage.getItem(
          "TagsValue"
        )}&Nottags=${localStorage.getItem(
          "NotTagsValue"
        )}&deliveryStatus=${localStorage.getItem(
          "deliveryStatusFilter"
        )}&financialStatus=${localStorage.getItem(
          "financialStatusFilter"
        )}&customFilter=${localStorage.getItem(
          "customFilterValue"
        )}&fullfilemtAt=${localStorage.getItem(
          "fullfilemtAt"
        )}&App=${localStorage.getItem("App")}&Channel=${localStorage.getItem(
          "Channel"
        )}&Return=${localStorage.getItem("Return")}&Risk=${localStorage.getItem(
          "Risk"
        )}&OrderStatus=${customFilterOrderStatusArray}
        &deliveryMethod=${customFilterOrderDeliveryMethod}`
      )
      .then((res) => {
        //console.log("Response data", res.data.orders);
        setTotalOrderLength(res.data.TotalOrderLength);
        res.data.magicAssign.isOn == 1
          ? setmagicSwitch(true)
          : setmagicSwitch(false);
        setstaffArrayForAllOrderList(res.data.orderAssigneeID);

        var assineeObject = {};
        res.data.staffArray.forEach((item) => {
          assineeObject[item._id] = item.first_name + " " + item.last_name;
        });
        setstaffArrayForAllOrder(assineeObject);
        var mainOrder = res.data.orders;
        mainOrder.forEach((item, i) => {
          item.Assigned_To =
            res.data.orderAssigneeID[i] == "0"
              ? "Unassigned"
              : assineeObject[res.data.orderAssigneeID[i]];
        });
        //console.log("mainOrder", mainOrder);
        setCount(mainOrder);
        setstaffArray(res.data.staffArray);
        var StaffArrayForPickListPopup=[]
        for(var i=0;i<res.data.staffArray.length;i++)
        {
var staffObject={
  label:res.data.staffArray[i].first_name+" "+res.data.staffArray[i].last_name,
  value:res.data.staffArray[i]._id
}
StaffArrayForPickListPopup.push(staffObject)
        }
        setstaffArrayPopup(StaffArrayForPickListPopup)
        localStorage.setItem("staffArray", JSON.stringify(res.data.staffArray));
        setstaffArrayForAllOrderList(res.data.orderAssigneeID);
        var assineeObject = {};
        res.data.staffArray.forEach((item) => {
          assineeObject[item._id] = item.first_name + " " + item.last_name;
        });
        setstaffArrayForAllOrder(assineeObject);
        //console.log("staffArrayForAllOrder", staffArrayForAllOrder);
        //console.log("staffArrayForAllOrderList", staffArrayForAllOrderList);

        var orderFields = res.data.orderFields.order_fields.split(",");
        //setorderFieldsArray(orderFields);
        setorderFieldsArray(
          localStorage.getItem("orderFields") == undefined
            ? "name,total_price,id,tags,updated_at".split(
                ","
              )
            : localStorage.getItem("orderFields").split(",")
        );

        setcheckedOrderArray([]);
        setcheckedAllOrderArray([]);
        setrefresh(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [category]);

  useEffect(() => {
    console.log("category update");
    setrefresh(false);
    axios
      .get(
        `${baseURL}/orderList?shop=${localStorage.getItem(
          "shop"
        )}&code=${localStorage.getItem(
          "code"
        )}&assignType=${category}&page=${localStorage.getItem(
          "page"
        )}&limit=${localStorage.getItem(
          "limit"
        )}&sort=orderDetail.${localStorage.getItem(
          "sort"
        )}&sortType=${localStorage.getItem(
          "sortType"
        )}&fulfillmentFilter=${localStorage.getItem(
          "fulfillmentStatusFilter"
        )}&fromDate=${localStorage.getItem(
          "fromDate"
        )}&ToDate=${localStorage.getItem("ToDate")}&tags=${localStorage.getItem(
          "TagsValue"
        )}&Nottags=${localStorage.getItem(
          "NotTagsValue"
        )}&deliveryStatus=${localStorage.getItem(
          "deliveryStatusFilter"
        )}&financialStatus=${localStorage.getItem(
          "financialStatusFilter"
        )}&customFilter=${localStorage.getItem(
          "customFilterValue"
        )}&fullfilemtAt=${localStorage.getItem(
          "fullfilemtAt"
        )}&App=${localStorage.getItem("App")}&Channel=${localStorage.getItem(
          "Channel"
        )}&Return=${localStorage.getItem("Return")}&Risk=${localStorage.getItem(
          "Risk"
        )}&OrderStatus=${customFilterOrderStatusArray}
        &deliveryMethod=${customFilterOrderDeliveryMethod}`
      )
      .then((res) => {
        //console.log("Response data", res.data.orders);
        setTotalOrderLength(res.data.TotalOrderLength);
        res.data.magicAssign.isOn == 1
          ? setmagicSwitch(true)
          : setmagicSwitch(false);
        setstaffArrayForAllOrderList(res.data.orderAssigneeID);

        var assineeObject = {};
        res.data.staffArray.forEach((item) => {
          assineeObject[item._id] = item.first_name + " " + item.last_name;
        });
        setstaffArrayForAllOrder(assineeObject);
        var mainOrder = res.data.orders;
        mainOrder.forEach((item, i) => {
          item.Assigned_To =
            res.data.orderAssigneeID[i] == "0"
              ? "Unassigned"
              : assineeObject[res.data.orderAssigneeID[i]];
        });
        //console.log("mainOrder", mainOrder);
        setCount(mainOrder);
        setstaffArray(res.data.staffArray);
        localStorage.setItem("staffArray", JSON.stringify(res.data.staffArray));
        setstaffArrayForAllOrderList(res.data.orderAssigneeID);
        var assineeObject = {};
        res.data.staffArray.forEach((item) => {
          assineeObject[item._id] = item.first_name + " " + item.last_name;
        });
        setstaffArrayForAllOrder(assineeObject);
        var orderFields = res.data.orderFields.order_fields.split(",");
        setorderFieldsArray(
          localStorage.getItem("orderFields") == undefined
            ? "name,total_price,id,tags,updated_at".split(
                ","
              )
            : localStorage.getItem("orderFields").split(",")
        );

        setcheckedOrderArray([]);
        setcheckedAllOrderArray([]);
        setrefresh(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    page,
    limit,
    sortByOrderId,
    sortByOrderIdValueToAPI,
    clickOnDateFilter,
    clickOnFulfimentFilter,
    clickOnDeliveryMethod,
    clickOnOrderStatus,
    clickOnTags,
    clickOnNotTags,
    clickOnDeliveryFilter,
    clickOnFinancialFilter,
    clickOnApp,
    clickOnChannel,
    clickOnReturn,
    clickOnRisk,
  ]);
  useEffect(() => {
    console.log("checked array");
  }, [checkedOrderArray]);
  const clickOnRefresh = () => {
    console.log("clicked on refresh");
    setrefresh(false);
    axios
      .get(
        `${baseURL}/orderList?shop=${localStorage.getItem(
          "shop"
        )}&code=${localStorage.getItem(
          "code"
        )}&assignType=${category}&page=${localStorage.getItem(
          "page"
        )}&limit=${localStorage.getItem(
          "limit"
        )}&sort=orderDetail.${localStorage.getItem(
          "sort"
        )}&sortType=${localStorage.getItem(
          "sortType"
        )}&fulfillmentFilter=${localStorage.getItem(
          "fulfillmentStatusFilter"
        )}&fromDate=${localStorage.getItem(
          "fromDate"
        )}&ToDate=${localStorage.getItem("ToDate")}&tags=${localStorage.getItem(
          "TagsValue"
        )}&Nottags=${localStorage.getItem(
          "NotTagsValue"
        )}&deliveryStatus=${localStorage.getItem(
          "deliveryStatusFilter"
        )}&financialStatus=${localStorage.getItem(
          "financialStatusFilter"
        )}&customFilter=${localStorage.getItem(
          "customFilterValue"
        )}&fullfilemtAt=${localStorage.getItem(
          "fullfilemtAt"
        )}&App=${localStorage.getItem("App")}&Channel=${localStorage.getItem(
          "Channel"
        )}&Return=${localStorage.getItem("Return")}&Risk=${localStorage.getItem(
          "Risk"
        )}&OrderStatus=${customFilterOrderStatusArray}
        &deliveryMethod=${customFilterOrderDeliveryMethod}`
      )
      .then((res) => {
        //console.log("Response data", res.data.orders);
        setTotalOrderLength(res.data.TotalOrderLength);

        setstaffArrayForAllOrderList(res.data.orderAssigneeID);
        var assineeObject = {};
        res.data.staffArray.forEach((item) => {
          assineeObject[item._id] = item.first_name + " " + item.last_name;
        });
        setstaffArrayForAllOrder(assineeObject);
        var mainOrder = res.data.orders;
        mainOrder.forEach((item, i) => {
          item.Assigned_To =
            res.data.orderAssigneeID[i] == "0"
              ? "Unassigned"
              : assineeObject[res.data.orderAssigneeID[i]];
        });
        //console.log("mainOrder", mainOrder);
        setCount(mainOrder);
        setcheckedOrderArray([]);
        setcheckedAllOrderArray([]);
        /*  setstaffArrayForAllOrderList(res.data.orderAssigneeID);
        var assineeObject = {};
        res.data.staffArray.forEach((item) => {
          assineeObject[item.id] = item.first_name + " " + item.last_name;
        });
        setstaffArrayForAllOrder(assineeObject); */
        //console.log("staffArrayForAllOrder", staffArrayForAllOrder);
        //console.log("staffArrayForAllOrderList", staffArrayForAllOrderList);
        setrefresh(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    console.log("filter fulfillment", filterFulfillment);
  }, [filterFulfillment]);
  useEffect(() => {
    console.log("category update");
    setrefresh(false);
    axios
      .get(
        `${baseURL}/orderList?shop=${localStorage.getItem(
          "shop"
        )}&code=${localStorage.getItem(
          "code"
        )}&assignType=${category}&page=${localStorage.getItem(
          "page"
        )}&limit=${localStorage.getItem(
          "limit"
        )}&sort=orderDetail.${localStorage.getItem(
          "sort"
        )}&sortType=${localStorage.getItem(
          "sortType"
        )}&fulfillmentFilter=${localStorage.getItem(
          "fulfillmentStatusFilter"
        )}&fromDate=${localStorage.getItem(
          "fromDate"
        )}&ToDate=${localStorage.getItem("ToDate")}&tags=${localStorage.getItem(
          "TagsValue"
        )}&Nottags=${localStorage.getItem(
          "NotTagsValue"
        )}&deliveryStatus=${localStorage.getItem(
          "deliveryStatusFilter"
        )}&financialStatus=${localStorage.getItem(
          "financialStatusFilter"
        )}&customFilter=${localStorage.getItem(
          "customFilterValue"
        )}&fullfilemtAt=${localStorage.getItem(
          "fullfilemtAt"
        )}&App=${localStorage.getItem("App")}&Channel=${localStorage.getItem(
          "Channel"
        )}&Return=${localStorage.getItem("Return")}&Risk=${localStorage.getItem(
          "Risk"
        )}&OrderStatus=${customFilterOrderStatusArray}&deliveryMethod=${customFilterOrderDeliveryMethod}`
      )
      .then((res) => {
        //console.log("Response data", res.data.orders);
        setTotalOrderLength(res.data.TotalOrderLength);
        res.data.magicAssign.isOn == 1
          ? setmagicSwitch(true)
          : setmagicSwitch(false);
        setstaffArrayForAllOrderList(res.data.orderAssigneeID);
        var assineeObject = {};
        res.data.staffArray.forEach((item) => {
          assineeObject[item._id] = item.first_name + " " + item.last_name;
        });
        setstaffArrayForAllOrder(assineeObject);
        var mainOrder = res.data.orders;
        mainOrder.forEach((item, i) => {
          item.Assigned_To =
            res.data.orderAssigneeID[i] == "0"
              ? "Unassigned"
              : assineeObject[res.data.orderAssigneeID[i]];
        });
        //console.log("mainOrder", mainOrder);
        setCount(mainOrder);
        setstaffArray(res.data.staffArray);
        localStorage.setItem("staffArray", JSON.stringify(res.data.staffArray));
        setstaffArrayForAllOrderList(res.data.orderAssigneeID);
        var assineeObject = {};
        res.data.staffArray.forEach((item) => {
          assineeObject[item._id] = item.first_name + " " + item.last_name;
        });
        setstaffArrayForAllOrder(assineeObject);
        var orderFields = res.data.orderFields.order_fields.split(",");
        setorderFieldsArray(
          localStorage.getItem("orderFields") == undefined
            ? "name,total_price,id,tags,updated_at".split(
                ","
              )
            : localStorage.getItem("orderFields").split(",")
        );
        setcheckedOrderArray([]);
        setcheckedAllOrderArray([]);
        setrefresh(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    page,
    limit,
    sortByOrderId,
    sortByOrderIdValueToAPI,
    clickOnDateFilter,
    clickOnFulfimentFilter,
    clickOnDeliveryMethod,
    clickOnOrderStatus,
    clickOnTags,
    clickOnNotTags,
    clickOnDeliveryFilter,
    clickOnFinancialFilter,
    customFilterValue,
  ]);
  useEffect(() => {
    console.log("useeffect test");
    axios
      .post(`${baseURL}/verfyToken`, {
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        if (res.data.statusCode == 200) {
        } else {
          navigate(`/`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    console.log("testinmggggggggggggggggggg");
    axios
      .post(`${baseURL}/getCreatedCustomFilter`, {
        shopName: localStorage.getItem("shop"),
      })
      .then((res) => {
        console.log("Custom filter array", res.data);
        var customArray = [
          {
            label: "All",
            value: "All",
          },
        ];
        for (var i = 0; i < res.data.data.length; i++) {
          var customObject = {
            label: res.data.data[i].customFilterName,
            value: res.data.data[i]._id,
          };
          customArray.push(customObject);
        }
        setcustomFilterSelectData(customArray);
        setCustomeFilterListData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [customFilterCreated]);
  useEffect(()=>{
    setrefresh(false)
    axios.post(`${baseURL}/getSettingData`,{store_name:localStorage.getItem("shop")})
    .then((res)=>{
        console.log("data",res.data)
        if(res.data.data.length==0)
        {
    setrefresh(true)
           

        }
        else{
            console.log(res.data.data)
            setpickListGenerationType(res.data.data.pickListGenerationType)
            setrefresh(true)



            /* setimageFilename2(jsonData.imageFilename2) 
            setimageCommand2(jsonData.imageCommand2) 
            setimageFilename3(jsonData.imageFilename3) 
            setimageCommand3(jsonData.imageCommand3) 
            setimageFilename4(jsonData.imageFilename4) 
            setimageCommand4(jsonData.imageCommand4) 
            setimageFilename5(jsonData.imageFilename5) 
            setimageCommand5(jsonData.imageCommand5) 
            setimageFilename6(jsonData.imageFilename6) 
            setimageCommand6(jsonData.imageCommand6) 
            setimageFilename7(jsonData.imageFilename7) 
            setimageCommand7(jsonData.imageCommand7) 
            setimageFilename8(jsonData.imageFilename8) 
            setimageCommand8(jsonData.imageCommand8) 
            setimageFilename9(jsonData.imageFilename9) 
            setimageCommand9(jsonData.imageCommand9)  */
    /* console.log("jsonDate",jsonData) */
        }

    })
    .catch((err)=>{
        console.log("err",err)
    })

  },[])
  const clickOnSortByOrderID = (key) => {
    setsortByOrderIdValueToAPI(key);
    localStorage.setItem("sort", sortByOrderIdValueToAPI);

    if (sortByOrderId == -1) {
      setsortByOrderId(1);
      localStorage.setItem("sortType", sortByOrderId);
    } else {
      setsortByOrderId(-1);
      localStorage.setItem("sortType", sortByOrderId);
    }
    /* setrefresh(false);
    setsortByOrderId(!sortByOrderId);

    console.log("clickOnSortByOrderID");
    console.log("clickOnSortByOrderIDbefore", count);
    let sortedProducts;
    if (sortByOrderId) {
      sortedProducts = count.sort((p1, p2) =>
        p1[key] > p2[key]
          ? 1
          : p1[key] < p2[key]
          ? -1
          : 0
      );
    } else {
      sortedProducts = count.sort((p1, p2) =>
        p1[key] < p2[key]
          ? 1
          : p1[key] > p2[key]
          ? -1
          : 0
      );
    }
    setCount(sortedProducts);
    console.log("clickOnSortByOrderIDafter", sortedProducts);
    setTimeout(() => {
      setrefresh(true);
    }, 50); */
  };
  const clickOnSortByOrderIDScan = (key) => {
    console.log("key", key);
    /*  setsortByOrderIdValueToAPI(key);
     localStorage.setItem("sort",sortByOrderIdValueToAPI)
 
     if(sortByOrderId==-1)
     {
     setsortByOrderId(1);
     localStorage.setItem("sortType",sortByOrderId)
 
     }
     else
     {
     setsortByOrderId(-1);
     localStorage.setItem("sortType",sortByOrderId)
 
 
     } */
    setrefresh(false);
    /* setsortByOrderId(!sortByOrderId); */

    console.log("clickOnSortByOrderID");
    console.log("clickOnSortByOrderIDbefore", scanCount);
    setscanOrderIDSort(!scanOrderIDSort);
    let sortedProducts;

    if (scanOrderIDSort) {
      if (key == "total_price") {
        sortedProducts = scanCount.sort((p1, p2) =>
          parseFloat(p1[key]) > parseFloat(p2[key])
            ? 1
            : parseFloat(p1[key]) < parseFloat(p2[key])
            ? -1
            : 0
        );
      } else {
        sortedProducts = scanCount.sort((p1, p2) =>
          p1[key] > p2[key] ? 1 : p1[key] < p2[key] ? -1 : 0
        );
      }
    } else {
      if (key == "total_price") {
        sortedProducts = scanCount.sort((p1, p2) =>
          parseFloat(p1[key]) < parseFloat(p2[key])
            ? 1
            : parseFloat(p1[key]) > parseFloat(p2[key])
            ? -1
            : 0
        );
      } else {
        sortedProducts = scanCount.sort((p1, p2) =>
          p1[key] < p2[key] ? 1 : p1[key] > p2[key] ? -1 : 0
        );
      }
    }
    setScanCount(sortedProducts);
    console.log("clickOnSortByOrderIDafter", sortedProducts);
    setTimeout(() => {
      setrefresh(true);
    }, 50);
  };
  const clickOnSortByOrderAmount = () => {
    setrefresh(false);
    setsortByOrderAmount(!sortByOrderAmount);

    console.log("clickOnSortByOrderAmount");
    console.log("clickOnSortByOrderAmountbefore", count);
    let sortedProducts;
    if (sortByOrderAmount) {
      sortedProducts = count.sort((p1, p2) =>
        parseInt(p1.total_price) > parseInt(p2.total_price)
          ? 1
          : parseInt(p1.total_price) < parseInt(p2.total_price)
          ? -1
          : 0
      );
    } else {
      sortedProducts = count.sort((p1, p2) =>
        parseInt(p1.total_price) < parseInt(p2.total_price)
          ? 1
          : parseInt(p1.total_price) > parseInt(p2.total_price)
          ? -1
          : 0
      );
    }
    setCount(sortedProducts);
    console.log("clickOnSortByOrderAmountafter", sortedProducts);
    setTimeout(() => {
      setrefresh(true);
    }, 50);
  };
  const clickOnSortByOrderStatus = () => {
    setrefresh(false);
    setsortByOrderStatus(!sortByOrderStatus);

    console.log("clickOnSortByOrderStatus");
    console.log("clickOnSortByOrderStatusbefore", count);
    let sortedProducts;
    if (sortByOrderStatus) {
      sortedProducts = count.sort((p1, p2) =>
        p1.total_price > p2.total_price
          ? 1
          : p1.total_price < p2.total_price
          ? -1
          : 0
      );
    } else {
      sortedProducts = count.sort((p1, p2) =>
        parseInt(p1.total_price) < parseInt(p2.total_price)
          ? 1
          : parseInt(p1.total_price) > parseInt(p2.total_price)
          ? -1
          : 0
      );
    }
    setCount(sortedProducts);
    console.log("clickOnSortByOrderStatusafter", sortedProducts);
    setTimeout(() => {
      setrefresh(true);
    }, 50);
  };
  const handleCategoryChange = (index) => {
    setcategory(index);
    localStorage.setItem("category1", index);
    setcheckedOrderArray([]);
  };
  const handleCategoryNameChange = (index) => {
    setcategoryName(index);
    setAssignName(index);
  };
  const handleOrderCheckBox = (e, orderNumber) => {
    var newArr = [...checkedOrderArray];
    if (newArr.indexOf(orderNumber) != -1) {
      newArr.splice(newArr.indexOf(orderNumber), 1);
      setcheckedOrderArray(newArr);
    } else {
      newArr.push(orderNumber);
      setcheckedOrderArray(newArr);
    }
    console.log(checkedOrderArray);
  };
  const handleOrderFieldsCheckBox = (e, orderfields) => {
    if (orderFieldsArray.indexOf(orderfields) != -1) {
      var order = orderFieldsArray;
      order.splice(orderFieldsArray.indexOf(orderfields), 1);
      setorderFieldsArray(order);
      setrefresh(false); 
     setTimeout(() => {
        setrefresh(true);
      }, 5);
    } else {
      var order = orderFieldsArray;
      order.push(orderfields);
      setorderFieldsArray(order);
    }

    console.log(orderFieldsArray);
  };

  const clearAllFilters = () => {
    setfilterFulfillment("");
    setcustomFilterOrderStatusArray([])
    localStorage.setItem("fulfillmentStatusFilter", "");
    setvalueFilterByToDate("");
    setvalueFilterByFromDate("");
    localStorage.setItem("fromDate", "");
    localStorage.setItem("ToDate", "");
    setfilterTags([]);
    localStorage.setItem("TagsValue", JSON.stringify([]));
    setclickOnTags(!clickOnTags);
    setfilterNotTags([]);
    localStorage.setItem("NotTagsValue", JSON.stringify([]));
    setclickOnTags(!clickOnNotTags);
    setfilterDelivery("");
    localStorage.setItem("deliveryStatusFilter", "");
    setclickOnDeliveryFilter(!clickOnDeliveryFilter);
    setfilterFinancial("");
    localStorage.setItem("financialStatusFilter", "");
    setclickOnFinancialFilter(!clickOnFinancialFilter);

    setfilterApp([]);
    localStorage.setItem("App", JSON.stringify([]));
    setclickOnApp(!clickOnApp);
    setfilterChannel([]);
    localStorage.setItem("Channel", JSON.stringify([]));
    setclickOnChannel(!clickOnChannel);
    setfilterReturn([]);
    localStorage.setItem("Return", JSON.stringify([]));
    setclickOnReturn(!clickOnReturn);
    setfilterRisk([]);
    localStorage.setItem("Risk", JSON.stringify([]));
    setclickOnRisk(!clickOnRisk);
    setclickOnOrderStatus(!clickOnOrderStatus)
    setcustomFilterOrderStatusArray([])
    setclickOnDeliveryMethod(!clickOnDeliveryMethod)
    setcustomFilterOrderDeliveryMethod([])
    console.log("cleared");
  };

  const [customeFilterListData, setCustomeFilterListData] = useState([]);
  const [editcustomeFilterListData, setEditcustomeFilterListData] =
    useState(null);
  const [editid, setEditId] = useState(null);
  const [editCustomFilterName, setEditCustomFilterName] = useState([]);
  const [editFullFillment, setEditFullFillment] = useState([]);
  const [editOrderStatus, setEditOrderStatus] = useState([]);
  const [editOrderDeliveryMethod, setEditOrderDeliveryMethod] = useState([]);


  const [editFinancialStatus, setFinancialStatus] = useState([]);
  const [editDeliveryStatus, setEditDeliveryStatus] = useState([]);
  const [editTaggedWith, setTaggedWith] = useState([]);
  const [editNotTaggedWith, setEditNotTaggedWith] = useState([]);
  const [editApp, setEditApp] = useState([]);
  const [editChannel, setEditChannel] = useState([]);
  const [editReturnStatus, setEditReturnStatus] = useState([]);
  const [editRisk, setEditRisk] = useState([]);
  const [editFromData, setEditFromDate] = useState("");
  const [editToData, setEditToDate] = useState("");
  const [editfulfilledAt, setEditFulfilledAt] = useState();
  // const [customeDtate, setCustomeDate] = useState()
  // const fulfilledAtdata = editfulfilledAt
  function handleOpenAntModelCustomeFilter() {
    setOpenAntModalCustomFilter(true);
    setOpenNewModalCustomFilter(false);
  }
  // To Edit Custom Filter
  function handleCustomeFilterEdit(id) {
    setOpenNewModalCustomFilter(false);
    setEditAntModalCustomFilter(true);

    axios
      .post(`${baseURL}/getCreatedCustomFilter`, {
        shopName: localStorage.getItem("shop"),
      })
      .then((res) => {
        for (var i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i]._id === id) {
            console.log("what res", res.data.data[i]);
            var formValues = {
              shop: res.data.data[i].shop,
              staffName: res.data.data[i].staffName,
              staffId: res.data.data[i].staffId,
              customFilterName: res.data.data[i].customFilterName,
              orderStatus: res.data.data[i].orderStatus,
              deliveryMethod: res.data.data[i].deliveryMethod,

              fulfilmentStatus: res.data.data[i].fulfilmentStatus,
              financialStatus: res.data.data[i].financialStatus.map(
                (item) => item
              ),
              deliveryStatus: res.data.data[i].deliveryStatus.map(
                (item) => item
              ),
              taggedWith: res.data.data[i].taggedWith.map((item) => item),
              notTaggedWith: res.data.data[i].notTaggedWith.map((item) => item),
              appName: res.data.data[i].appName.map((item) => item),
              channelName: res.data.data[i].channelName.map((item) => item),
              return: res.data.data[i].return.map((item) => item),
              risk: res.data.data[i].risk.map((item) => item),
              fromDate: moment(res.data.data[i].fromDate).format(
                "YYYY-MM-DD"
              ),
              toDate: moment(res.data.data[i].toDate).format(
                "YYYY-MM-DD"
              ),
              fullfilemtAt: res.data.data[i].fullfilemtAt,
            };
            console.log("formnValuessss", formValues);
            setEditFulfilledAt(formValues.fullfilemtAt);
            // setCustomeDate({ fromDate: formValues.fromDate, toDate: formValues.toDate })
            setEditcustomeFilterListData(formValues);
            setEditCustomFilterName(formValues.customFilterName);
            setEditFullFillment(formValues.fulfilmentStatus);
            setEditOrderStatus(formValues.orderStatus)
            setEditOrderDeliveryMethod(formValues.deliveryMethod)

            setFinancialStatus(formValues.financialStatus);
            setEditDeliveryStatus(formValues.deliveryStatus);
            setTaggedWith(formValues.taggedWith);
            setEditNotTaggedWith(formValues.notTaggedWith);
            setEditApp(formValues.appName);
            setEditChannel(formValues.channelName);
            setEditReturnStatus(formValues.return);
            setEditRisk(formValues.risk);
            setEditFromDate(formValues.fromDate);
            setEditToDate(formValues.toDate);
            setEditId(res.data.data[i]._id);
            handleRadioOptions(formValues.fromDate, formValues.toDate);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  console.log("customeDtate", editfulfilledAt);
  function handleCustomeFilterDelete(id) {
    if (id) {
      swal({
        title: "Are you sure?",
        text: "You won't to delete the custome filter",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        setOpenNewModalCustomFilter(false);
        if (willDelete) {
          axios
            .post(`${baseURL}/deleteCustomFilters`, { customFilterID: id })
            .then((res) => {
              console.log(res);
              window.location.reload();
            })
            .catch((error) => {
              console.log("error", error);
            });
          setrefresh(true);
        }
      });
    }
  }

  function handleEditCustomForm(id) {
    setEditAntModalCustomFilter(false);
    console.log("edit id", id);
    var editcustomFilterData = {
      shop: localStorage.getItem("shop"),
      staffName: localStorage.getItem("staffName"),
      staffId: localStorage.getItem("staffId"),
      customFilterName: editCustomFilterName,
      orderStatus: [...editOrderStatus],
      deliveryMethod: [...editOrderDeliveryMethod],
      fulfilmentStatus: [...editFullFillment],
      financialStatus: [...editFinancialStatus],
      deliveryStatus: [...editDeliveryStatus],
      taggedWith: [...editTaggedWith],
      notTaggedWith: [...editNotTaggedWith],
      appName: [...editApp],
      channelName: [...editChannel],
      return: [...editReturnStatus],
      risk: [...editRisk],
      fullfilemtAt: editfulfilledAt,
      fromDate: editFromData,
      toDate: editToData,
    };
    axios
      .post(`${baseURL}/updateCustomFilters`, {
        customFilterID: id,
        customFiltersData: editcustomFilterData,
      })
      .then((res) => {console.log(res)
        swal({
          title: "Updated",
          text: "Custom filter updated successfully",
          icon: "success",
          button: "Done",
        });
        console.log("reload")
        
        //window.location.reload();
        setcustomFilterValue(id)
        console.log("id",id)
      })
      .catch((error) => console.log(error));
  }
  function handleRadioOptions(FromData, ToData) {
    const dateFromdate = moment(FromData, "YYYY-MM-DD").toDate();
    const dateTodate = moment(ToData, "YYYY-MM-DD").toDate();
    const difference = dateFromdate - dateTodate;
    const differenceInDays = Math.floor(difference / (1000 * 60 * 60 * 24));
    const dtaa = Math.abs(differenceInDays);
    console.log("calculated date", Math.abs(differenceInDays));
    if (dtaa == 1) setvalueFilterByDate(1);
    else if (dtaa == 7) setvalueFilterByDate(2);
    else if (dtaa == 30) setvalueFilterByDate(3);
    else if (dtaa == 90) setvalueFilterByDate(4);
    else if (dtaa == 365) setvalueFilterByDate(5);
    else setvalueFilterByDate(6);
  }

  return (
    <div className="App">
      <Layout status="1">
        {refresh ? (
          <div>
            {/* Modal to show list of custome filters */}
            <AntModalCustomFilter
              title="List Of Custom Filters"
              style={{
                top: 20,
              }}
              open={openNewModalCustomFilter}
              onOk={() => {
                setOpenNewModalCustomFilter(false);
                createCustomFilter();
              }}
              onCancel={() => setOpenNewModalCustomFilter(false)}
              width={1000}
              footer={[
                <Button
                  key="back"
                  onClick={() => setOpenNewModalCustomFilter(false)}
                >
                  Close
                </Button>,
                <Button
                  variant="success"
                  size="sm"
                  className="mx-1"
                  type="primary"
                  onClick={() => handleOpenAntModelCustomeFilter()}
                >
                  New Custom filters
                </Button>,
              ]}
            >
              <hr />
              <Table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Custome Filter</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {customeFilterListData.map((item, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item.customFilterName}</td>
                      <td>
                        <Button
                          variant="success"
                          size="sm"
                          className="mx-1"
                          onClick={() => handleCustomeFilterEdit(item._id)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="success"
                          size="sm"
                          className="mx-1"
                          onClick={() => handleCustomeFilterDelete(item._id)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </AntModalCustomFilter>
            {/* Modal to Edit the  custome filter  */}
            <AntModalCustomFilter
              title="Update Custom Filter"
              style={{
                top: 20,
              }}
              open={editAntModalCustomFilter}
              onOk={() => {
                setEditAntModalCustomFilter(false);
              }}
              onCancel={() => setEditAntModalCustomFilter(false)}
              width={1000}
              footer={[
                <Button
                  key="back"
                  onClick={() => setEditAntModalCustomFilter(false)}
                >
                  Close
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {
                    handleEditCustomForm(editid);
                  }}
                >
                  Update
                </Button>,
              ]}
            >
              <hr />
              <Title level={5} style={{ marginTop: "10px" }}>
                Custom Filter Name
              </Title>
              <Input
                style={{ marginTop: "10px" }}
                value={`${editcustomeFilterListData?.customFilterName}`}
                onChange={(e) => {
                  setEditcustomeFilterListData((prevData) => ({
                    ...prevData,
                    customFilterName: e.target.value,
                  }));
                  setEditCustomFilterName(e.target.value);
                }}
              />
              <u>
                <Title level={5} style={{ marginTop: "10px" }}>
                  Select Filters
                </Title>
              </u>
              <Title level={5}>Order Status</Title>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                value={editOrderStatus}
                onChange={(selectedValues) => {
                  setEditOrderStatus(selectedValues);
                }}
                options={optionsForOrderStatus}
              />
              <Title level={5}>Delivery Method</Title>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                value={editOrderDeliveryMethod}
                onChange={(selectedValues) => {
                  setEditOrderDeliveryMethod(selectedValues);
                }}
                options={optionsForOrderDeliveryMethod}
              />
              <Title level={5}>Fullfilment Status</Title>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                value={editFullFillment}
                onChange={(selectedValues) => {
                  setEditFullFillment(selectedValues);
                }}
                options={options}
              />

              <Title level={5} style={{ marginTop: "10px" }}>
                Tagged With
              </Title>
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                value={editTaggedWith}
                onChange={(selectedValues) => {
                  setTaggedWith(selectedValues);
                }}
                options={optionsTags}
              />
              <Title level={5} style={{ marginTop: "10px" }}>
                Not Tagged With
              </Title>
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                value={editNotTaggedWith}
                onChange={(selectedValues) => {
                  setEditNotTaggedWith(selectedValues);
                }}
                options={optionsTags}
              />
              <Title level={5} style={{ marginTop: "10px" }}>
                Delivery Status
              </Title>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                value={editDeliveryStatus}
                onChange={(selectedValues) => {
                  setEditDeliveryStatus(selectedValues);
                }}
                options={optionsDeliveryStatus}
              />
              <Title level={5} style={{ marginTop: "10px" }}>
                Financial Status
              </Title>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                value={editFinancialStatus}
                onChange={(selectedValues) => {
                  setFinancialStatus(selectedValues);
                }}
                options={optionsFinancialStatus}
              />

              <Title level={5} style={{ marginTop: "10px" }}>
                Fulfilled At{" "}
              </Title>
              {editfulfilledAt ? (
                <DatePicker
                  format={dateFormatOne}
                  defaultValue={dayjs(editfulfilledAt, "YYYY-MM-DD")}
                  onChange={onChangeFulfilledAt}
                />
              ) : (
                ""
              )}

              <Title level={5} style={{ marginTop: "10px" }}>
                App
              </Title>
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                value={editApp}
                onChange={(selectedValues) => {
                  setEditApp(selectedValues);
                }}
                options={optionsApp}
              />
              <Title level={5} style={{ marginTop: "10px" }}>
                Channel
              </Title>
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                value={editChannel}
                onChange={(selectedValues) => {
                  setEditChannel(selectedValues);
                }}
                // onChange={onChangeChannel}
                options={optionsChannel}
              />

              <Title level={5} style={{ marginTop: "10px" }}>
                Return Status
              </Title>
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                value={editReturnStatus}
                // onChange={onChangeReturnStatus}
                onChange={(selectedValues) => {
                  setEditReturnStatus(selectedValues);
                }}
                options={optionsReturn}
              />
              <Title level={5} style={{ marginTop: "10px" }}>
                Risk
              </Title>
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                value={editRisk}
                onChange={(selectedValues) => {
                  setEditRisk(selectedValues);
                }}
                // onChange={onChangeRisk}
                options={optionsRisk}
              />

              <Title level={5} style={{ marginTop: "10px" }}>
                Date
              </Title>
              <Radio.Group onChange={onChange} value={valueFilterByDate}>
                <Space direction="vertical">
                  <Radio value={1}>Today</Radio>
                  <Radio value={2}>Last 7 days</Radio>
                  <Radio value={3}>Last 30 days</Radio>
                  <Radio value={4}>Last 90 days</Radio>
                  <Radio value={5}>Last 365 days</Radio>

                  <Radio value={6}>
                    Custom
                    {valueFilterByDate === 6 ? (
                      <Space direction="vertical" size={12}>
                        <RangePicker
                        
                          format={dateFormat}
                          onChange={onChangeCustomDate}
                          onOk={onOk}
                          defaultValue={[
                            dayjs(editFromData, dateFormat),
                            dayjs(editToData, dateFormat),
                          ]}
                        />
                      </Space>
                    ) : null}
                  </Radio>
                  <Button
                    type="primary"
                    onClick={() => setvalueFilterByDate(0)}
                  >
                    Clear
                  </Button>
                </Space>
              </Radio.Group>
            </AntModalCustomFilter>
            {/* Modal to create Custome filter */}
            <AntModalCustomFilter
              title="Create Custom Filter"
              style={{
                top: 20,
              }}
              open={openAntModalCustomFilter}
              onOk={() => {
                setOpenAntModalCustomFilter(false);
                createCustomFilter();
              }}
              onCancel={() => setOpenAntModalCustomFilter(false)}
              width={1000}
              footer={[
                <Button
                  key="back"
                  onClick={() => setOpenAntModalCustomFilter(false)}
                >
                  Close
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {
                    if (customFilterName.length != 0) {
                      setOpenAntModalCustomFilter(false);
                      createCustomFilter();
                      setCustomFilterName("");
                    } else {
                      swal({
                        title: "Error",
                        text: "Please provide a custom filter name",
                        icon: "error",
                        button: "Done",
                      });
                    }
                  }}
                >
                  Create
                </Button>,
              ]}
            >
              <hr />
              <Title level={5} style={{ marginTop: "10px" }}>
                Custom Filter Name
              </Title>
              <Input
                placeholder="Custom filter name"
                style={{ marginTop: "10px" }}
                value={customFilterName}
                onChange={(e) => {
                  setCustomFilterName(e.target.value);
                }}
              />
              <u>
                <Title level={5} style={{ marginTop: "10px" }}>
                  Select Filters
                </Title>
              </u>
              <Title level={5}>Order Status</Title>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                onChange={handleChangeonCustomFilterOrderStatusArray}
                options={optionsForOrderStatus}
              />
              <Title level={5}>Delivery Method</Title>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                onChange={handleChangeonCustomFilterOrderDeliveryMethod}
                options={optionsForOrderDeliveryMethod}
              />
              <Title level={5}>Fullfilment Status</Title>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                onChange={handleChangeonCustomFilterFulfillmentStatus}
                options={options}
              />

              <Title level={5} style={{ marginTop: "10px" }}>
                Tagged With
              </Title>
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                onChange={onChangeTags}
                options={optionsTags}
              />
              <Title level={5} style={{ marginTop: "10px" }}>
                Not Tagged With
              </Title>
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                onChange={onChangeNotTags}
                options={optionsTags}
              />
              <Title level={5} style={{ marginTop: "10px" }}>
                Delivery Status
              </Title>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                onChange={handleChangeonCustomFilterDeliveryStatus}
                options={optionsDeliveryStatus}
              />
              <Title level={5} style={{ marginTop: "10px" }}>
                Financial Status
              </Title>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                onChange={handleChangeonCustomFilterFinancialStatus}
                options={optionsFinancialStatus}
              />
              <Title level={5} style={{ marginTop: "10px" }}>
                Fulfilled At
              </Title>
              <DatePicker onChange={onChangeFulfilledAt} />

              <Title level={5} style={{ marginTop: "10px" }}>
                App
              </Title>
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                onChange={onChangeApp}
                options={optionsApp}
              />
              <Title level={5} style={{ marginTop: "10px" }}>
                Channel
              </Title>
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                onChange={onChangeChannel}
                options={optionsChannel}
              />
              <Title level={5} style={{ marginTop: "10px" }}>
                Return Status
              </Title>
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                onChange={onChangeReturnStatus}
                options={optionsReturn}
              />
              <Title level={5} style={{ marginTop: "10px" }}>
                Risk
              </Title>
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                onChange={onChangeRisk}
                options={optionsRisk}
              />
              <Title level={5} style={{ marginTop: "10px" }}>
                Date
              </Title>
              <Radio.Group onChange={onChange} value={valueFilterByDate}>
                <Space direction="vertical">
                  <Radio value={1}>Today</Radio>
                  <Radio value={2}>Last 7 days</Radio>
                  <Radio value={3}>Last 30 days</Radio>
                  <Radio value={4}>Last 90 days</Radio>
                  <Radio value={5}>Last 365 days</Radio>

                  <Radio value={6}>
                    Custom
                    {valueFilterByDate === 6 ? (
                      <Space direction="vertical" size={12}>
                        <RangePicker
                          format="YYYY-MM-DD"
                          onChange={onChangeCustomDate}
                          onOk={onOk}
                          defaultValue={[
                            dayjs(moment(new Date()).subtract(59,'d').format(
                              "YYYY-MM-DD"
                            ), dateFormat),
                            dayjs(moment(new Date()).format(
                              "YYYY-MM-DD"
                            ), dateFormat),
                          ]}
                        />
                      </Space>
                    ) : null}
                  </Radio>
                  <Button
                    type="primary"
                    onClick={() => setvalueFilterByDate(0)}
                  >
                    Clear
                  </Button>
                </Space>
              </Radio.Group>
            </AntModalCustomFilter>
            <AntModalCustomFilter
              title="Picklist Generation"
              style={{
                top: 20,
              }}
              open={openAntModalPickListPopup}
              onOk={() => {
                setOpenAntModalPickListPopup(false);
                setpickListAssignUserID("")
                setpickListAssignUserName("")
              }}
              onCancel={() => {
                setpickListAssignUserID("")
                setpickListAssignUserName("")
                setOpenAntModalPickListPopup(false)}}
              width={1000}
              footer={[
                <Button
                  key="back"
                  onClick={() =>{ 
                    setpickListAssignUserID("")
                    setpickListAssignUserName("")
                    setOpenAntModalPickListPopup(false)}}
                >
                  Close
                </Button>
              ]}
            >
              {refreshModal?(
                <div>
                <hr />
                <h5>Only generate Picklist PDF</h5>
                <p>Picklist will be generated for selected orders without changing order assignment.</p>
                <Button type="primary" onClick={handleGeneratePickListPopUp}>Generate Picklist PDF</Button>
                <br/>
                <br/>
                
                <h5>Generate picklist PDF and assign orders</h5>
                <p>Picklist will be generated for selected orders and selected orders can be assigned to a specific user.</p>
                <Select
                  allowClear
                  style={{
                    width: "30%",
                  }}
                  placeholder="Please Select"
                  onChange={handlePickListAssignOrder}
                  options={staffArrayPopup}
                />
                <br/>
                <br/>
                {pickListAssignUserID!=""?<Button type="primary" onClick={generatePickListAndAssign}>Assign & Generate Picklist PDF</Button>:""}
  
  
               
                
                
                </div>
              ): (<center>
              <div className="spinner-border my-5" role="status">
                <span className="sr-only"></span>
              </div>
            </center>)}
              
              
            </AntModalCustomFilter>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Customize your Orders</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Add/Remove to customize order fields
                <br />
                {count.length > 0
                  ? Object.keys(count[0]).map(function (key, index) {
                      if (
                        typeof count[0][key] === "object"  &&
                        count[0][key] != null
                      ) {
                      } else {
                        if (orderFieldsArray.indexOf(key) != -1) {
                          return (
                            <Form.Check
                              type="checkbox"
                              key={index}
                              label={key.split("_").join(" ").toUpperCase()}
                              checked={orderFieldsArray.indexOf(key) != -1}
                              onChange={(e) => {
                                //e.target.checked=!e.target.checked
                                console.log(e.target.checked);
                                handleOrderFieldsCheckBox(e, key);
                              }}
                            />
                          );
                        } else {
                          return (
                            <Form.Check
                              type="checkbox"
                              key={index}
                              label={key.split("_").join(" ").toUpperCase()}
                              onChange={(e) =>
                                handleOrderFieldsCheckBox(e, key)
                              }
                            />
                          );
                        }
                      }
                    })
                  : ""}
              </Modal.Body>
              <Modal.Footer>
                <Button type="secondary" onClick={handleClose}>
                  Discard
                </Button>
                <Button type="primary" onClick={handleApplyOrderFields}>
                  Apply
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showAssign} onHide={handleCloseAssgn}>
              {checkedOrderArray.length > 0 ? (
                <div>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Assign to a specific picker/packer
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Choose to assign order
                    <br />
                    {staffArray.map((item, i) => {
                      return (
                        <Form.Check
                          type="radio"
                          id={i + 1}
                          key={i}
                          name="picker"
                          label={item.first_name + " " + item.last_name}
                          onChange={() => {
                            setpickerPackerID(item._id);
                            setpickerPackerFirstName(item.first_name);
                            console.log("id", pickerPackerID);
                          }}
                        />
                      );
                    })}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button type="secondary" onClick={handleCloseAssgn}>
                      Discard
                    </Button>
                    <Button type="primary" onClick={handleApplyAssign}>
                      Apply
                    </Button>
                  </Modal.Footer>
                </div>
              ) : (
                {}
              )}
            </Modal>
            <Modal show={showAssignNew} onHide={handleCloseAssgnNew}>
              {checkedOrderArray.length > 0 ? (
                <div>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Assign to a specific picker/packer
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Choose to assign order
                    <br />
                    {staffArray.map((item, i) => {
                      return (
                        <Form.Check
                          type="radio"
                          id={i + 1}
                          key={i}
                          name="picker"
                          label={item.first_name + " " + item.last_name}
                          onChange={() => {
                            setpickerPackerID(item._id);
                            setpickerPackerFirstName(item.first_name);
                            console.log("id", pickerPackerID);
                          }}
                        />
                      );
                    })}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button type="secondary" onClick={handleCloseAssgnNew}>
                      Discard
                    </Button>
                    <Button type="primary" onClick={handleAllToSpec}>
                      Apply
                    </Button>
                  </Modal.Footer>
                </div>
              ) : (
                {}
              )}
            </Modal>
            <Modal show={showAssignMagic} onHide={handleCloseMagic}>
              <div>
                <Modal.Header closeButton>
                  <Modal.Title>Give your value for Magic Assign</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Choose order filed, Operator and provide value
                  <hr />
                  <h6>Expression Stored</h6>
                  <hr />
                  {NumberMagicAssign.map((item, i) => {
                    return (
                      <div key={i}>
                        <h6>Expression-{i + 1}</h6>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Field</th>
                              <th>Operator</th>
                              <th>Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item.field}</td>
                              <td>{item.operator}</td>
                              <td>{item.value}</td>
                            </tr>
                          </tbody>
                        </Table>
                        {i + 1 < NumberMagicAssign.length ? (
                          <center>
                            <b>{mainOperator}</b>
                          </center>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    );
                  })}
                  <hr />
                  <h6>Update Operator</h6>
                  <Form.Select
                    aria-label="Default select example"
                    style={{ width: "200px" }}
                    defaultValue={mainOperator}
                    onChange={(e) => {
                      setmainOperator(e.target.value);
                    }}
                  >
                    <option value="">--Select--</option>
                    <option value="AND">AND</option>
                    <option value="OR">OR</option>
                  </Form.Select>
                  <hr />
                  <h6>Update Expression</h6>
                  <hr />
                  {NumberMagicAssign.map((item, i) => {
                    return (
                      <Card index={i} key={i} className="my-2">
                        <h6>Update Expression-{i + 1}</h6>
                        <div>
                          {NumberMagicAssign.length == i + 1 && i != 0 ? (
                            <div className="d-flex justify-content-between">
                              <h3></h3>
                              <Button
                                variant="danger"
                                size="sm"
                                className="my-2 mx-2"
                                onClick={removeExp}
                              >
                                remove
                              </Button>
                            </div>
                          ) : (
                            <div></div>
                          )}

                          <div>
                            {/* <Form.Label className="fs-6">Order Field</Form.Label> */}
                            <div className="d-flex justify-content-between">
                              <Form.Label className="mx-5">
                                <b>Field</b>{" "}
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                style={{ width: "200px" }}
                                defaultValue={item.field}
                                onChange={(e) => {
                                  setmagicAssignFieldData(e.target.value);
                                  console.log("filed", magicAssignFieldData);
                                  onHandleMultiMagiCExpField(e.target.value, i);
                                }}
                              >
                                <option value="">--Select--</option>
                                {Object.keys(magicAssignFields).map(function (
                                  key,
                                  index
                                ) {
                                  if (
                                    typeof magicAssignFields[key] ===
                                      "object" &&
                                    magicAssignFields[key] != null
                                  ) {
                                  } else {
                                    return (
                                      <option key={index} value={key}>
                                        {key}
                                      </option>
                                    );
                                  }
                                })}
                                {/*        <option value="1">App ID</option>
                          <option value="2">Order ID</option>
                          <option value="3">Order Value</option> */}
                              </Form.Select>
                            </div>
                          </div>
                          <div>
                            {/*            <Form.Label className="fs-6">Operators</Form.Label> */}
                            <div className="d-flex justify-content-between">
                              <Form.Label className="mx-3">
                                <b>Operator</b>{" "}
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                style={{ width: "200px" }}
                                defaultValue={item.operator}
                                onChange={(e) => {
                                  setmagicAssignOperatorData(e.target.value);
                                  console.log(
                                    "opretator",
                                    magicAssignOperatorData
                                  );
                                  onHandleMultiMagiCExpOperator(
                                    e.target.value,
                                    i
                                  );
                                }}
                              >
                                <option value="">--Select--</option>
                                <option value="=">=</option>
                                <option value="!=">!=</option>
                                <option value="<">{"<"}</option>
                                <option value=">">{">"}</option>
                                <option value="<=">{"<="}</option>
                                <option value=">=">{">="}</option>
                              </Form.Select>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <Form.Label className="mx-5">
                              <b>Value</b>{" "}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="value"
                              style={{
                                height: "40px",
                                paddingLeft: "10px",
                                width: "200px",
                              }}
                              defaultValue={item.value}
                              onChange={(e) => {
                                setmagicAssignValueData(e.target.value);
                                console.log("value", magicAssignValueData);
                                onHandleMultiMagiCExpValue(e.target.value, i);
                              }}
                            />
                          </div>
                        </div>
                      </Card>
                    );
                  })}
                  <Button
                    variant="primary"
                    disabled={NumberMagicAssign.length > 2 ? true : false}
                    onClick={clickOnAddExpression}
                  >
                    Add
                  </Button>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="secondary" onClick={handleCloseMagic}>
                    Discard
                  </Button>
                  {/* <Button type="primary" onClick={handleMagicApply}>
                    Apply
                  </Button> */}
                  <Button type="primary" onClick={handleMagicApplyMulti}>
                    Apply Changes
                  </Button>
                </Modal.Footer>
              </div>
            </Modal>
            <hr />
            <div className="d-flex justify-content-between">
              <div>
                <Row>
                  <div className="d-flex justify-content-start">
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="55"
                      height="55"
                      fill="currentColor"
                      className="bi bi-inbox-fill text-dark"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm-1.17-.437A1.5 1.5 0 0 1 4.98 3h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z" />
                    </svg>
                    <div className="mx-1">
                      <Row>
                        <Col>My Orders</Col>
                      </Row>
                      <Row>
                        <Col>
                          <SplitButton
                            as={ButtonGroup}
                            key="1"
                            id={`dropdown-button-drop`}
                            size="sm"
                            variant="secondary"
                            title={
                              category == "0" ? "Unassigned" : categoryName
                            }
                          >
                            <Dropdown.Item
                              eventKey="1"
                              onClick={() => {
                                handleCategoryChange("0");
                                handleCategoryNameChange("Unassigned");
                              }}
                            >
                              Unassigned
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="3"
                              onClick={() => {
                                handleCategoryChange("-1");
                                handleCategoryNameChange("All Orders");
                              }}
                            >
                              All Orders
                            </Dropdown.Item>
                            {staffArray.map((item, i) => {
                              return (
                                <Dropdown.Item
                                  eventKey="2"
                                  key={i}
                                  onClick={() => {
                                    handleCategoryChange(item._id);
                                    handleCategoryNameChange(
                                      item.first_name + " " + item.last_name
                                    );
                                  }}
                                >
                                  {item.first_name + " " + item.last_name}
                                </Dropdown.Item>
                              );
                            })}
                          </SplitButton>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Row>
              </div>

              <div className="my-2">
              {JSON.parse(View!="undefined"?View:"[]").indexOf('Assign and Unassign feature')==-1?"":(
                category != "-1" ? (
                  category == "0" ? (
                    <div>
                      {/* <Button
                    variant="success"
                    size="sm"
                    className="mx-1 my-1"
                    onClick={handleApplyAutoAssign}
                  >
                    Auto Assign
                  </Button> */}
                    </div>
                  ) : (
                    <Button
                      variant="danger"
                      size="sm"
                      className="mx-1 my-1"
                      onClick={handleApplyUnAssign}
                    >
                      Unassign
                    </Button>
                  )
                ) : (
                  <Button
                    variant="danger"
                    size="sm"
                    className="mx-1 my-1"
                    onClick={handleAllUnassign}
                  >
                    Unassign
                  </Button>
                )
              )}
              {JSON.parse(View!="undefined"?View:"[]").indexOf('Assign and Unassign feature')==-1?"":(
                category != "-1" ? (
                  category == "0" ? (
                    <Button
                      variant="success"
                      size="sm"
                      className="mx-1"
                      onClick={handleShowAssign}
                    >
                      Assign to Specific Picker/Packer
                    </Button>
                  ) : (
                    <Button
                      variant="secondary"
                      size="sm"
                      className="mx-1"
                      onClick={handleShowAssign}
                    >
                      Assign order to another Picker/Packer
                    </Button>
                  )
                ) : (
                  <Button
                    variant="success"
                    size="sm"
                    className="mx-1"
                    onClick={handleShowAssignNew}
                  >
                    Assign to Specific Picker/Packer
                  </Button>
                )
              )}
                {JSON.parse(View!="undefined"?View:"[]").indexOf('PickList Generation')==-1?"":(
                  pickListGenerationType=="ORDER"?(
                    <Button
                    variant="success"
                    size="sm"
                    className="mx-1"
                    onClick={handleGeneratePickList}
                  >
                    Generate PickList
                  </Button>
                  ):""
               
        )}
                {JSON.parse(View!="undefined"?View:"[]").indexOf('PickList Generation')==-1?"":(
pickListGenerationType=="PRODUCT"?(
  <Button
                  variant="success"
                  size="sm"
                  className="mx-1"
                  onClick={handleGeneratePickList}
                >
                  Generate PickList by product
                </Button>
):""
                
                )}
                <Button
                  variant="success"
                  size="sm"
                  className="mx-1"
                  onClick={() => setOpenNewModalCustomFilter(true)}
                >
                  Create Custom filters
                </Button>
                <Button size="sm">Export</Button>
              </div>
            </div>

            <div className="my-2 d-flex justify-content-between">
              <div className="h6" style={{ fontSize: "15px" }}>
                {count.length} Orders. Updated{" "}
                {showTime >= 2
                  ? showTime + " Minutes ago."
                  : "a few seconds ago"}
              </div>

              <div className="d-flex justify-content-start">
                <Form
                  className="mx-1"
                  onSubmit={async (e) => {
                    await searchItems();
                    e.preventDefault();
                  }}
                >
                  <Form.Control
                    autoFocus
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                    onChange={(e) => setorderSerach(e.target.value)}
                  />
                </Form>
                {/*  <Button
                  style={{ backgroundColor: "#C1FF05", color: "black" }}
                  size="sm"
                >
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Magic Assign is On"
                      checked={magicSwitch}
                      onClick={() => {
                        magicSwitch
                          ? setmagicSwitch(false)
                          : setmagicSwitch(true);
                        console.log("magicAssign", magicSwitch);
                        handleMagicAssign();
                      }}
                    />
                  </Form>
                </Button> */}
                <Button
                  onClick={clickOnRefresh}
                  variant="light"
                  size="sm"
                  className="mx-1"
                >
                  <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    fill="currentColor"
                    className="bi bi-arrow-repeat"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path
                      fillRule="evenodd"
                      d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                    />
                  </svg>
                </Button>
                {/* <Button onClick={handleShowMagic} variant="light" size="sm">
                  <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    fill="currentColor"
                    className="bi bi-gear-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                  </svg>
                </Button> */}
                <Button
                  onClick={handleShow}
                  variant="light"
                  size="sm"
                  className="mx-1"
                >
                  <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    fill="currentColor"
                    className="bi bi-filter-square-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm.5 5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1 0-1zM4 8.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm2 3a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </Button>
              </div>
            </div>
            <hr />
            <Flex wrap="wrap" gap="small">
              {/* <Button onClick={clearAllFilters} style={{padding:"5px",marginBottom:"10px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter" viewBox="0 0 16 16">
  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg> All
      </Button> */}
              <Select
                defaultValue={customFilterValue}
                style={{
                  width: 250,
                }}
                onChange={customFilterDataArraySelectChange}
                options={customFilterSelectData}
              />
              <Button
                onClick={showDrawerDeliveryMethod}
                style={{ padding: "5px", marginBottom: "10px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>{" "}
                Delivery Method
              </Button>
              <Button
                onClick={showDrawerOrderStatus}
                style={{ padding: "5px", marginBottom: "10px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>{" "}
                Order Status
              </Button>
              <Button
                onClick={showDrawer}
                style={{ padding: "5px", marginBottom: "10px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>{" "}
                Date
              </Button>
              <Button
                onClick={showDrawerFullfillment}
                style={{ padding: "5px", marginBottom: "10px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>{" "}
                Fullfilment Status
              </Button>
              
              <Button
                onClick={showDrawerTags}
                style={{ padding: "5px", marginBottom: "10px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>{" "}
                Tagged WIth
              </Button>
              <Button
                onClick={showDrawerNotTags}
                style={{ padding: "5px", marginBottom: "10px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>{" "}
                Not Tagged WIth
              </Button>
              <Button
                onClick={showDrawerDelivery}
                style={{ padding: "5px", marginBottom: "10px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>{" "}
                Delivery Status
              </Button>
              <Button
                onClick={showDrawerFinancial}
                style={{ padding: "5px", marginBottom: "10px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>{" "}
                Financial Status
              </Button>
              <Button
                onClick={showDrawerApp}
                style={{ padding: "5px", marginBottom: "10px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>{" "}
                App
              </Button>
              <Button
                onClick={showDrawerReturn}
                style={{ padding: "5px", marginBottom: "10px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>{" "}
                Return
              </Button>
              <Button
                onClick={showDrawerChannel}
                style={{ padding: "5px", marginBottom: "10px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>{" "}
                Channel
              </Button>
              <Button
                onClick={showDrawerRisk}
                style={{ padding: "5px", marginBottom: "10px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>{" "}
                Risk Level
              </Button>
              <Button
                onClick={clearAllFilters}
                danger
                style={{ padding: "5px", marginBottom: "10px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>{" "}
                Clear All Filters
              </Button>
            </Flex>
            <Drawer
              title="Filter by Date"
              placement="right"
              onClose={onClose}
              open={open}
            >
              <div>
                <Radio.Group onChange={onChange} value={valueFilterByDate}>
                  <Space direction="vertical">
                    <Radio value={1}>Today</Radio>
                    <Radio value={2}>Last 7 days</Radio>
                    <Radio value={3}>Last 30 days</Radio>
                    <Radio value={4}>Last 90 days</Radio>
                    <Radio value={5}>Last 365 days</Radio>

                    <Radio value={6}>
                      Custom
                      {valueFilterByDate === 6 ? (
                        <Space direction="vertical" size={12}>
                          <RangePicker
                            showTime={{
                              format: "HH:mm",
                            }}
                            format="YYYY-MM-DD HH:mm"
                            onChange={onChangeCustomDate}
                            onOk={onOk}
                          />
                        </Space>
                      ) : null}
                    </Radio>
                  </Space>
                </Radio.Group>
              </div>
              <br />
              <Button
                type="primary"
                onClick={() => {
                  setclickOnDateFilter(!clickOnDateFilter);
                  onClose();
                }}
              >
                Apply
              </Button>
            </Drawer>
            <Drawer
              title="Filter by Fullfilent Status"
              placement="right"
              onClose={onCloseFullfillment}
              open={openFullfillment}
            >
              <div>
                <Radio.Group
                  onChange={onChangeSortFullfilled}
                  value={filterFulfillment}
                >
                  <Space direction="vertical">
                    <Radio value={"FULFILLED"}>Fulfilled</Radio>
                    <Radio value={"UNFULFILLED"}>Unfulfilled</Radio>
                    <Radio value={"PARTIALLY_FULFILLED"}>
                      Partially Fulfilled
                    </Radio>
                    <Radio value={"IN_PROGRESS"}>
                    IN_PROGRESS
                    </Radio>
                    <Radio value={"SCHEDULED"}>Scheduled</Radio>
                    <Radio value={"OPEN"}>OPEN</Radio>
                    <Radio value={"PENDING_FULFILLMENT"}>PENDING_FULFILLMENT</Radio>
                    <Radio value={"RESTOCKED"}>RESTOCKED</Radio>


                    <Radio value={"ON_HOLD"}>On Hold</Radio>
                  </Space>
                </Radio.Group>
              </div>
              <br />
              <Button
                type="primary"
                onClick={() => {
                  setclickOnFulfilmentFilter(!clickOnFulfimentFilter);
                  onCloseFullfillment();
                }}
              >
                Apply
              </Button>
            </Drawer>
            <Drawer
              title="Filter by Delivery Method"
              placement="right"
              onClose={onCloseDeliveryMethod}
              open={openDeliveryMethod}
            >
              <div>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                onChange={handleChangeonCustomFilterOrderDeliveryMethod}
                options={optionsForOrderDeliveryMethod}
              />
              </div>
              <br />
              <Button
                type="primary"
                onClick={() => {
                  setclickOnDeliveryMethod(!clickOnDeliveryMethod);
                  onCloseDeliveryMethod();
                }}
              >
                Apply
              </Button>
            </Drawer>
            <Drawer
              title="Filter by Order Status"
              placement="right"
              onClose={onCloseOrderStatus}
              open={openOrderStatus}
            >
              <div>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please Select"
                onChange={handleChangeonCustomFilterOrderStatusArray}
                options={optionsForOrderStatus}
              />
              </div>
              <br />
              <Button
                type="primary"
                onClick={() => {
                  setclickOnOrderStatus(!clickOnOrderStatus);
                  onCloseOrderStatus();
                }}
              >
                Apply
              </Button>
            </Drawer>
            <Drawer
              title="Filter by Tagged With"
              placement="right"
              onClose={onCloseTags}
              open={openTags}
            >
              <div>
                {/*  <Checkbox.Group options={optionsTags} onChange={onChangeTags} /> */}
                <Select
                  mode="tags"
                  style={{
                    width: "100%",
                  }}
                  value={filterTags}
                  placeholder="Tags Mode"
                  onChange={onChangeTags}
                  options={optionsTags}
                />
              </div>
              <br />
              <Button
                type="primary"
                onClick={() => {
                  setclickOnTags(!clickOnTags);
                  onCloseTags();
                }}
              >
                Apply
              </Button>
            </Drawer>
            <Drawer
              title="Filter by Not Tagged With"
              placement="right"
              onClose={onCloseNotTags}
              open={openNotTags}
            >
              <div>
                {/*  <Checkbox.Group options={optionsTags} onChange={onChangeTags} /> */}
                <Select
                  mode="tags"
                  style={{
                    width: "100%",
                  }}
                  value={filterNotTags}
                  placeholder="Tags Mode"
                  onChange={onChangeNotTags}
                  options={optionsTags}
                />
              </div>
              <br />
              <Button
                type="primary"
                onClick={() => {
                  setclickOnNotTags(!clickOnNotTags);
                  onCloseNotTags();
                }}
              >
                Apply
              </Button>
            </Drawer>
            <Drawer
              title="Filter by Delivery Status"
              placement="right"
              onClose={onCloseDeliveryStatus}
              open={openDeliveryDrawer}
            >
              <div>
                {/* <Space direction="vertical">
      <Checkbox onChange={onChangeSortFullfilled} name={"fulfilled"}>Fulfilled</Checkbox>
      <Checkbox onChange={onChangeSortFullfilled} name={null}>Unfulfilled</Checkbox>
      <Checkbox onChange={onChangeSortFullfilled} name={"partially fulfilled"}>Partially Fulfilled</Checkbox>
      <Checkbox onChange={onChangeSortFullfilled} name={"scheduled"}>Scheduled</Checkbox>
      <Checkbox onChange={onChangeSortFullfilled} name={"onhold"}>On Hold</Checkbox>
      <Checkbox onChange={onChangeSortFullfilled} name={"requestDeclined"}>Request Declined</Checkbox> 
      </Space> */}
                <Radio.Group
                  onChange={onChangeDeliveryStatus}
                  value={filterDelivery}
                >
                  <Space direction="vertical">
                    <Radio value={"ATTEMPTED_DELIVERY"}>ATTEMPTED_DELIVERY</Radio>
                    <Radio value={"CANCELED"}>CANCELED</Radio>
                    <Radio value={"CONFIRMED"}>
                    CONFIRMED
                    </Radio>
                    <Radio value={"DELIVERED"}>DELIVERED</Radio>
                    <Radio value={"FAILURE"}>FAILURE</Radio>
                    <Radio value={"FULFILLED"}>FULFILLED</Radio>
                    <Radio value={"IN_TRANSIT"}>IN_TRANSIT</Radio>

                    <Radio value={"LABEL_PRINTED"}>LABEL_PRINTED</Radio>
                    <Radio value={"LABEL_PURCHASED"}>LABEL_PURCHASED</Radio>
                    <Radio value={"LABEL_VOIDED"}>LABEL_VOIDED</Radio>
                    <Radio value={"MARKED_AS_FULFILLED"}>MARKED_AS_FULFILLED</Radio>
                    <Radio value={"NOT_DELIVERED"}>NOT_DELIVERED</Radio>
                    <Radio value={"OUT_FOR_DELIVERY"}>OUT_FOR_DELIVERY</Radio>
                    <Radio value={"PICKED_UP"}>PICKED_UP</Radio>
                    <Radio value={"READY_FOR_PICKUP"}>READY_FOR_PICKUP</Radio>
                    <Radio value={"SUBMITTED"}>SUBMITTED</Radio>

                  </Space>
                </Radio.Group>
              </div>
              <br />
              <Button
                type="primary"
                onClick={() => {
                  setclickOnDeliveryFilter(!clickOnDeliveryFilter);
                  onCloseDeliveryStatus();
                }}
              >
                Apply
              </Button>
            </Drawer>
            <Drawer
              title="Filter by Financial Status"
              placement="right"
              onClose={onCloseFinancialStatus}
              open={openFinancialDrawer}
            >
              <div>
                {/* <Space direction="vertical">
      <Checkbox onChange={onChangeSortFullfilled} name={"fulfilled"}>Fulfilled</Checkbox>
      <Checkbox onChange={onChangeSortFullfilled} name={null}>Unfulfilled</Checkbox>
      <Checkbox onChange={onChangeSortFullfilled} name={"partially fulfilled"}>Partially Fulfilled</Checkbox>
      <Checkbox onChange={onChangeSortFullfilled} name={"scheduled"}>Scheduled</Checkbox>
      <Checkbox onChange={onChangeSortFullfilled} name={"onhold"}>On Hold</Checkbox>
      <Checkbox onChange={onChangeSortFullfilled} name={"requestDeclined"}>Request Declined</Checkbox> 
      </Space> */}
                <Radio.Group
                  onChange={onChangeFinancialStatus}
                  value={filterFinancial}
                >
                  <Space direction="vertical">
                    <Radio value={"PENDING"}>PENDING</Radio>
                    <Radio value={"AUTHORIZED"}>AUTHORIZED</Radio>
                    <Radio value={"PAID"}>PAID</Radio>
                    <Radio value={"REFUNDED"}>REFUNDED</Radio>
                    <Radio value={"VOIDED"}>VOIDED</Radio>
                    <Radio value={"PARTIALLY_REFUNDED"}>
                    PARTIALLY_REFUNDED
                    </Radio>
                    <Radio value={"PARTIALLY_PAID"}>PARTIALLY_PAID</Radio>
                    <Radio value={"EXPIRED"}>EXPIRED</Radio>

                  </Space>
                </Radio.Group>
              </div>
              <br />
              <Button
                type="primary"
                onClick={() => {
                  setclickOnFinancialFilter(!clickOnFinancialFilter);
                  onCloseFinancialStatus();
                }}
              >
                Apply
              </Button>
            </Drawer>
            <Drawer
              title="Filter by App"
              placement="right"
              onClose={onCloseApp}
              open={openAppDrawer}
            >
              <div>
                <Select
                  mode="tags"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please Select"
                  onChange={onChangeApp}
                  options={optionsApp}
                />
              </div>
              <br />
              <Button
                type="primary"
                onClick={() => {
                  setclickOnApp(!clickOnApp);
                  onCloseApp();
                }}
              >
                Apply
              </Button>
            </Drawer>
            <Drawer
              title="Filter by Channel"
              placement="right"
              onClose={onCloseChannel}
              open={openChannelDrawer}
            >
              <div>
                <Select
                  mode="tags"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please Select"
                  onChange={onChangeChannel}
                  options={optionsChannel}
                />
              </div>
              <br />
              <Button
                type="primary"
                onClick={() => {
                  setclickOnChannel(!clickOnChannel);
                  onCloseChannel();
                }}
              >
                Apply
              </Button>
            </Drawer>
            <Drawer
              title="Filter by Return"
              placement="right"
              onClose={onCloseReturn}
              open={openReturnDrawer}
            >
              <div>
                <Select
                  mode="tags"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please Select"
                  onChange={onChangeReturnStatus}
                  options={optionsReturn}
                />
              </div>
              <br />
              <Button
                type="primary"
                onClick={() => {
                  setclickOnReturn(!clickOnReturn);
                  onCloseReturn();
                }}
              >
                Apply
              </Button>
            </Drawer>
            <Drawer
              title="Filter by Risk Level"
              placement="right"
              onClose={onCloseRisk}
              open={openRiskDrawer}
            >
              <div>
                {/*  <Checkbox.Group options={optionsTags} onChange={onChangeTags} /> */}
                <Select
                  mode="tags"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please Select"
                  onChange={onChangeRisk}
                  options={optionsRisk}
                />
              </div>
              <br />
              <Button
                type="primary"
                onClick={() => {
                  setclickOnRisk(!clickOnRisk);
                  onCloseRisk();
                }}
              >
                Apply
              </Button>
            </Drawer>
            <br></br>
            {scanCount.length == 0 ? (
              ""
            ) : (
              <div style={{ marginBottom: "50px" }}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id={`default-checkbox`}
                          label=""
                          onClick={handleTickAllScanTable}
                        />
                      </th>
                      <th>
                        <b>#</b>
                      </th>
                      <th onClick={() => {
                        clickOnSortByOrderID("Assigned_To");
                        clickedColumnName=="Assigned_To"?setclickedColumnName(""):setclickedColumnName("Assigned_To")   
                      }}   style={{ cursor: "pointer" }}>Assigned To {clickedColumnName=="Assigned_To"?(
                        <svg
                          xmlns="https://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-up text-dark"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="https://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-down text-dark"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                          />
                        </svg>
                      )}</th>
                      {scanCount.length > 0
                        ? Object.keys(scanCount[0]).map(function (key, index) {
                            if (
                              typeof scanCount[0][key] === "object" &&
                              scanCount[0][key] != null
                            ) {
                            } else {
                              if (orderFieldsArray.indexOf(key) != -1) {
                                if (key == "name") {
                                  return (
                                    <th
                                      key={index}
                                      onClick={() => {
                                        clickOnSortByOrderIDScan(key);
                                        clickedColumnName == key
                                          ? setclickedColumnNameOfScanOrderID(
                                              ""
                                            )
                                          : setclickedColumnNameOfScanOrderID(
                                              key
                                            );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Name
                                      {clickedColumnNameOfScanOrderID == key ? (
                                        <svg
                                          xmlns="https://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-arrow-up text-dark"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="https://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-arrow-down text-dark"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                                          />
                                        </svg>
                                      )}
                                    </th>
                                  );
                                }
                                if (key == "totalPrice") {
                                  return (
                                    <th
                                      key={index}
                                      onClick={() => {
                                        clickOnSortByOrderIDScan(key);
                                        clickedColumnNameOfScanOrderID == key
                                          ? setclickedColumnNameOfScanOrderID(
                                              ""
                                            )
                                          : setclickedColumnNameOfScanOrderID(
                                              key
                                            );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Price
                                      {clickedColumnNameOfScanOrderID == key ? (
                                        <svg
                                          xmlns="https://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-arrow-up text-dark"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="https://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-arrow-down text-dark"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                                          />
                                        </svg>
                                      )}
                                    </th>
                                  );
                                } 
                                else {
                                  return (
                                    <th
                                      key={index}
                                      onClick={() => {
                                        clickOnSortByOrderIDScan(key);
                                        clickedColumnNameOfScanOrderID == key
                                          ? setclickedColumnNameOfScanOrderID(
                                              ""
                                            )
                                          : setclickedColumnNameOfScanOrderID(
                                              key
                                            );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {key
                                        .split("_")
                                        .join(" ")
                                        .charAt(0)
                                        .toUpperCase() +
                                        key.split("_").join(" ").slice(1)}
                                      {clickedColumnNameOfScanOrderID == key ? (
                                        <svg
                                          xmlns="https://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-arrow-up text-dark"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="https://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-arrow-down text-dark"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                                          />
                                        </svg>
                                      )}
                                    </th>
                                  );
                                }
                              }
                            }
                          })
                        : ""}
                    </tr>
                  </thead>
                  <tbody>
                    {scanCount.map((item, i) => {
                      return (
                        <tr key={i} style={{ cursor: "pointer" }}>
                          <td>
                            <Form.Check
                              type="checkbox"
                              id={`default-checkbox-1-${i}`}
                              label=""
                              checked={
                                checkedOrderArray.indexOf(item.name) == -1
                                  ? false
                                  : true
                              }
                              onChange={(e) => {
                                handleOrderCheckBox(e, item.name);
                              }}
                            />
                          </td>
                          <td
                            onClick={() => {
                              GotoOrderDetailsPage(item.name);
                            }}
                          >
                            {(parseInt(page) - 1) * parseInt(limit) + i + 1}
                          </td>
                          {/*  <td>{staffArrayForAllOrderList[i]=="0"?"Unassigned":staffArrayForAllOrder[staffArrayForAllOrderList[i]]}</td> */}
                            <td
                  onClick={() => {
                    GotoOrderDetailsPage(item.id);
                  }}
                >
                  {item.Assigned_To}
                </td>

                          {scanCount.length > 0
                            ? Object.keys(item).map(function (key, index) {
                                if (
                                  typeof item[key] === "object" &&
                                  item[key] != null
                                ) {
                                } else {
                                  if (orderFieldsArray.indexOf(key) != -1) {
                                    if (key == "fulfillment_status") {
                                      return (
                                        <td
                                          key={index}
                                          onClick={() => {
                                            GotoOrderDetailsPage(item.name);
                                          }}
                                        >
                                          {item[key] == null
                                            ? (item[key] = "Unfulfilled")
                                            : item[key]}
                                        </td>
                                      );
                                    }
                                    else if (key == "created_at") {
                                      return (
                                        <td
                                          key={index}
                                          onClick={() => {
                                            GotoOrderDetailsPage(item.name);
                                          }}
                                        >
                                          {moment(item[key]).calendar()}
                                        </td>
                                      );
                                    }
                                    else if (key == "updated_at") {
                                      return (
                                        <td
                                          key={index}
                                          onClick={() => {
                                            GotoOrderDetailsPage(item.name);
                                          }}
                                        >
                                          {moment(item[key]).calendar()}
                                        </td>
                                      );
                                    }
                                    else if (key == "processed_at") {
                                      return (
                                        <td
                                          key={index}
                                          onClick={() => {
                                            GotoOrderDetailsPage(item.name);
                                          }}
                                        >
                                          {moment(item[key]).calendar()}
                                        </td>
                                      );
                                    }
                                    else if (key == "tags") {
                                      return (
                                        <td
                                          key={index}
                                          onClick={() => {
                                            GotoOrderDetailsPage(item.name);
                                          }}
                                        >
                                          {item[key]
                                            .split(",").map((item, index) => {
                                              return (
                                                <Tag
                                                  key={index}
                                                  color={color[index % 4]}
                                                >
                                                  {item}
                                                </Tag>
                                              );
                                            })}
                                        </td>
                                      );
                                    } else {
                                      return (
                                        <td
                                          key={index}
                                          onClick={() => {
                                            GotoOrderDetailsPage(item.name);
                                          }}
                                        >
                                          {item[key]}
                                        </td>
                                      );
                                    }
                                  }
                                }
                              })
                            : ""}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
            <Form>
              <div
                style={{
                  width: "100%",
                  overflowX: "scroll",
                  backgroundColor: "#FDFEFE",
                }}
              >
                {count.length == 0 ? (
                  <center>
                    <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
                      No Orders
                    </h4>
                  </center>
                ) : (
                  <div>
                    <div>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>
                              <Form.Check
                                type="checkbox"
                                id={`default-checkbox`}
                                label=""
                                onClick={handleTickAll}
                              />
                            </th>
                            <th>
                              <b>#</b>
                            </th>
                            <th
                              onClick={() => {
                                clickOnSortByOrderID("Assigned_To");
                                clickedColumnName == "Assigned_To"
                                  ? setclickedColumnName("")
                                  : setclickedColumnName("Assigned_To");
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Assigned To{" "}
                              {clickedColumnName == "Assigned_To" ? (
                                <svg
                                  xmlns="https://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-arrow-up text-dark"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="https://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-arrow-down text-dark"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                                  />
                                </svg>
                              )}
                            </th>
                            {count.length > 0
                              ? Object.keys(count[0]).map(function (
                                  key,
                                  index
                                ) {
                                  if (
                                    typeof count[0][key] === "object"  &&
                                    count[0][key] != null
                                  ) {
                                  } else {
                                    if (orderFieldsArray.indexOf(key) != -1) {
                                      if (key == "order_number") {
                                        return (
                                          <th
                                            key={index}
                                            onClick={() => {
                                              clickOnSortByOrderID(key);
                                              clickedColumnName == key
                                                ? setclickedColumnName("")
                                                : setclickedColumnName(key);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {key
                                              .split("_")
                                              .join(" ")
                                              .charAt(0)
                                              .toUpperCase() +
                                              key.split("_").join(" ").slice(1)}
                                            {clickedColumnName == key ? (
                                              <svg
                                                xmlns="https://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-up text-dark"
                                                viewBox="0 0 16 16"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                                                />
                                              </svg>
                                            ) : (
                                              <svg
                                                xmlns="https://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-down text-dark"
                                                viewBox="0 0 16 16"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                                                />
                                              </svg>
                                            )}
                                          </th>
                                        );
                                      }
                                      if (key == "totalPrice") {
                                        return (
                                          <th
                                            key={index}
                                            onClick={() => {
                                              clickOnSortByOrderAmount();
                                              clickedColumnName == key
                                                ? setclickedColumnName("")
                                                : setclickedColumnName(key);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            Price
                                            {clickedColumnName == key ? (
                                              <svg
                                                xmlns="https://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-up text-dark"
                                                viewBox="0 0 16 16"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                                                />
                                              </svg>
                                            ) : (
                                              <svg
                                                xmlns="https://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-down text-dark"
                                                viewBox="0 0 16 16"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                                                />
                                              </svg>
                                            )}
                                          </th>
                                        );
                                      }
                                      if (key == "totalPrice") {
                                        return (
                                          <th
                                            key={index}
                                            onClick={() => {
                                              clickOnSortByOrderAmount();
                                              clickedColumnName == key
                                                ? setclickedColumnName("")
                                                : setclickedColumnName(key);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            Price
                                            {clickedColumnName == key ? (
                                              <svg
                                                xmlns="https://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-up text-dark"
                                                viewBox="0 0 16 16"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                                                />
                                              </svg>
                                            ) : (
                                              <svg
                                                xmlns="https://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-down text-dark"
                                                viewBox="0 0 16 16"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                                                />
                                              </svg>
                                            )}
                                          </th>
                                        );
                                      }
                                      if (key == "displayFulfillmentStatus") {
                                        return (
                                          <th
                                            key={index}
                                            onClick={() => {
                                              clickOnSortByOrderAmount();
                                              clickedColumnName == key
                                                ? setclickedColumnName("")
                                                : setclickedColumnName(key);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            Fulfillment
                                            {clickedColumnName == key ? (
                                              <svg
                                                xmlns="https://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-up text-dark"
                                                viewBox="0 0 16 16"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                                                />
                                              </svg>
                                            ) : (
                                              <svg
                                                xmlns="https://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-down text-dark"
                                                viewBox="0 0 16 16"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                                                />
                                              </svg>
                                            )}
                                          </th>
                                        );
                                      }
                                      if (key == "displayFinancialStatus") {
                                        return (
                                          <th
                                            key={index}
                                            onClick={() => {
                                              clickOnSortByOrderAmount();
                                              clickedColumnName == key
                                                ? setclickedColumnName("")
                                                : setclickedColumnName(key);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            Financial Status
                                            {clickedColumnName == key ? (
                                              <svg
                                                xmlns="https://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-up text-dark"
                                                viewBox="0 0 16 16"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                                                />
                                              </svg>
                                            ) : (
                                              <svg
                                                xmlns="https://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-down text-dark"
                                                viewBox="0 0 16 16"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                                                />
                                              </svg>
                                            )}
                                          </th>
                                        );
                                      } else {
                                        return (
                                          <th
                                            key={index}
                                            onClick={() => {
                                              clickOnSortByOrderID(key);
                                              clickedColumnName == key
                                                ? setclickedColumnName("")
                                                : setclickedColumnName(key);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {key
                                              .split("_")
                                              .join(" ")
                                              .charAt(0)
                                              .toUpperCase() +
                                              key.split("_").join(" ").slice(1)}
                                            {clickedColumnName == key ? (
                                              <svg
                                                xmlns="https://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-up text-dark"
                                                viewBox="0 0 16 16"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                                                />
                                              </svg>
                                            ) : (
                                              <svg
                                                xmlns="https://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-down text-dark"
                                                viewBox="0 0 16 16"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                                                />
                                              </svg>
                                            )}
                                          </th>
                                        );
                                      }
                                    }
                                  }
                                })
                              : ""}
                          </tr>
                        </thead>
                        <tbody>
                          {count.map((item, i) => {
                            return (
                              <tr key={i} style={{ cursor: "pointer" }}>
                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    id={`default-checkbox-1-${i}`}
                                    label=""
                                    checked={
                                      checkedOrderArray.indexOf(item.name) == -1
                                        ? false
                                        : true
                                    }
                                    onChange={(e) => {
                                      handleOrderCheckBox(e, item.name);
                                    }}
                                  />
                                </td>
                                <td
                                  onClick={() => {
                                    GotoOrderDetailsPage(item.name);
                                  }}
                                >
                                  {(parseInt(page) - 1) * parseInt(limit) +
                                    i +
                                    1}
                                </td>
                                {/*  <td>{staffArrayForAllOrderList[i]=="0"?"Unassigned":staffArrayForAllOrder[staffArrayForAllOrderList[i]]}</td> */}
                                <td
                                  onClick={() => {
                                    GotoOrderDetailsPage(item.name);
                                  }}
                                >
                                  {item.Assigned_To}
                                </td>

                                {count.length > 0
                                  ? Object.keys(item).map(function (
                                      key,
                                      index
                                    ) {
                                      if (
                                        typeof item[key] === "object" &&
                                        item[key] != null
                                      ) {
                                      } else {
                                        if (
                                          orderFieldsArray.indexOf(key) != -1
                                        ) {
                                          if (key == "fulfillment_status") {
                                            return (
                                              <td
                                                key={index}
                                                onClick={() => {
                                                  GotoOrderDetailsPage(item.name);
                                                }}
                                              >
                                                {item[key] == null
                                                  ? (item[key] = "Unfulfilled")
                                                  : item[key]}
                                              </td>
                                            );
                                          }
                                          else if (key == "created_at") {
                                            return (
                                              <td
                                                key={index}
                                                onClick={() => {
                                                  GotoOrderDetailsPage(item.name);
                                                }}
                                              >
                                                {moment(item[key]).calendar()}
                                              </td>
                                            );
                                          }
                                          else if (key == "updated_at") {
                                            return (
                                              <td
                                                key={index}
                                                onClick={() => {
                                                  GotoOrderDetailsPage(item.name);
                                                }}
                                              >
                                                {moment(item[key]).calendar()}
                                              </td>
                                            );
                                          }
                                          if (key == "processed_at") {
                                            return (
                                              <td
                                                key={index}
                                                onClick={() => {
                                                  GotoOrderDetailsPage(item.name);
                                                }}
                                              >
                                                {moment(item[key]).calendar()}
                                              </td>
                                            );
                                          }
                                          if (key == "tags") {
                                            return (
                                              <td
                                                key={index}
                                                onClick={() => {
                                                  GotoOrderDetailsPage(item.name);
                                                }}
                                              >
                                                {item[key]
                                                  .split(",").map((item, index) => {
                                                    return (
                                                      <Tag
                                                        key={index}
                                                        color={color[index % 4]}
                                                      >
                                                        {item}
                                                      </Tag>
                                                    );
                                                  })}
                                              </td>
                                            );
                                          } else {
                                            return (
                                              <td
                                                key={index}
                                                onClick={() => {
                                                  GotoOrderDetailsPage(item.name);
                                                }}
                                              >
                                                {item[key]}
                                              </td>
                                            );
                                          }
                                        }
                                      }
                                    })
                                  : ""}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                    <div className="my-2">
                      <Pagination
                        total={totalOrderLength}
                        showTotal={(total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`
                        }
                        defaultPageSize={limit}
                        defaultCurrent={page}
                        onChange={onPaginationChange}
                        pageSizeOptions={[5,10,20,30,40,50,60,70,80,90,100]}
                        showSizeChanger
                      />
                    </div>
                  </div>
                )}
              </div>
            </Form>
          </div>
        ) : (
          <center>
            <div className="spinner-border my-5" role="status">
              <span className="sr-only"></span>
            </div>
          </center>
        )}
      </Layout>
    </div>
  );
}

export default OrderPage;
